import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";

import { List, Paper } from "@material-ui/core";

import { useAuthContext } from "../../context/Auth/AuthContext";
import { useSettingsContext } from "../../context/SettingsContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TicketListItem from "../TicketListItem";
import TicketsListSkeleton from "../TicketsListSkeleton";
import { useStyles } from "./styles";

const ticketOrder = (ticket, state) => {
  if (ticket.order.order === "ASC" && ticket.order.column) {
    state = state.sort((a, b) => {
      const dateA = new Date(a[ticket.order.column]).getTime();
      const dateB = new Date(b[ticket.order.column]).getTime();

      if (a.isFixed && !b.isFixed) {
        return -1; // 'a' comes before 'b'
      }
      if (!a.isFixed && b.isFixed) {
        return 1; // 'b' comes before 'a'
      }
      if (a.isFixed && !b.isFixed) {
        return -1; // 'a' comes before 'b'
      }
      if (!a.isFixed && b.isFixed) {
        return 1; // 'b' comes before 'a'
      }

      if (dateA < dateB) {
        return -1;
      }
      if (dateA > dateB) {
        return 1;
      }
      return 0;
    });
  }

  if (ticket.order.order === "DESC" && ticket.order.column) {
    state = state.sort((a, b) => {
      const dateA = new Date(a[ticket.order.column]).getTime();
      const dateB = new Date(b[ticket.order.column]).getTime();

      if (a.isFixed && !b.isFixed) {
        return -1; // 'a' comes before 'b'
      }
      if (!a.isFixed && b.isFixed) {
        return 1; // 'b' comes before 'a'
      }
      if (a.isFixed && !b.isFixed) {
        return -1; // 'a' comes before 'b'
      }
      if (!a.isFixed && b.isFixed) {
        return 1; // 'b' comes before 'a'
      }

      if (dateA > dateB) {
        return -1;
      }
      if (dateA < dateB) {
        return 1;
      }
      return 0;
    });
  }

  return state;
};

const Reducer = (state, action) => {
  if (action.type === "LOAD_TICKETS") {
    const newTickets = action.payload.tickets;
    newTickets.forEach((ticket) => {
      const ticketIndex = state.findIndex((t) => t.id === ticket.id);
      if (ticketIndex !== -1) {
        state[ticketIndex] = ticket;
      } else {
        state.push(ticket);
      }
    });

    state = ticketOrder({ ...action.payload }, state);
    return [...state];
  }

  if (action.type === "RESET_UNREAD") {
    const ticketId = action.payload;

    const ticketIndex = state.findIndex((t) => t.id === ticketId);
    if (ticketIndex !== -1) {
      state[ticketIndex].unreadMessages = 0;
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET") {
    const ticket = action.payload;

    const ticketIndex = state.findIndex((t) => t.id === ticket.id);
    if (ticketIndex !== -1) {
      state[ticketIndex] = ticket;
    } else {
      state.unshift(ticket);
    }

    state = ticketOrder(ticket, state);
    return [...state];
  }

  if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
    const ticket = action.payload;

    const ticketIndex = state.findIndex((t) => t.id === ticket.id);
    if (ticketIndex !== -1) {
      state[ticketIndex] = ticket;
      state.unshift(state.splice(ticketIndex, 1)[0]);
    } else {
      state.unshift(ticket);
    }

    state = ticketOrder(ticket, state);

    return [...state];
  }

  if (action.type === "UPDATE_TICKET_CONTACT") {
    const contact = action.payload;
    const ticketIndex = state.findIndex((t) => t.contactId === contact.id);
    if (ticketIndex !== -1) {
      state[ticketIndex].contact = contact;
    }
    return [...state];
  }

  if (action.type === "DELETE_TICKET") {
    const ticketId = action.payload;
    const ticketIndex = state.findIndex((t) => t.id === ticketId);
    if (ticketIndex !== -1) {
      state.splice(ticketIndex, 1);
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const TicketsList = ({
  status,
  searchParam,
  subTabSearchParam,
  anchorEl,
  filters,
  showAll,
  selectedQueueIds,
  selectedUsersIds,
  style,
  tab,
  searchClicked,
  arrowAnswering,
  arrowWaiting,
  setFunctionsDeleteTicketObject,
  setLoadingTickets,
  loadingTickets,
}) => {
  const classes = useStyles();
  const [ticketsList, dispatch] = useReducer(Reducer, []);
  const lastTicket = useRef(null);
  const { getSettingValue } = useSettingsContext();
  const {
    user,
    onlineUsers,
    connections,
    connectionsMeta,
    ticketsNoQueue,
    useGetTickets,
    registerEvent,
    unregisterEvent,
    socket,
  } = useAuthContext();

  const [pageNumber, setPageNumber] = useState(1);
  const [lastIdTagged, setLastIdTagged] = useState({
    ticketId: 0,
    userTicketId: 0,
  });
  const [searchedList, setSearchedList] = useState([]);
  const [selectedProfilesIdsWhatsapp, setSelectedProfilesIdsWhatsapp] =
    useState([]);
  const [selectedProfilesIdsWhatsappApi, setSelectedProfilesIdsWhatsappApi] =
    useState([]);
  const [selectedProfilesIdsMeta, setSelectedProfilesIdsMeta] = useState([]);

  const [changeIsFixed, setChangeIsFixed] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 950);

  const [hasMoreSearch, setHasMoreSearch] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 950);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { tickets, hasMore, loading } = useGetTickets({
    pageNumber,
    status,
    showAll,
    queueIds: JSON.stringify(selectedQueueIds),
    userIds: JSON.stringify(selectedUsersIds),
    tab,
    order: arrowAnswering || arrowWaiting,
    column: getSettingValue("ticketOrder"),
    selectedUsersIds: JSON.stringify(selectedUsersIds),
    changeIsFixed,
  });

  useEffect(() => {
    async function init() {
      if (connections && connectionsMeta) {
        setSelectedProfilesIdsWhatsapp(ticketsNoQueue.noQueueWhatsapp || []);
        setSelectedProfilesIdsMeta(ticketsNoQueue.noQueueMeta || []);
        setSelectedProfilesIdsWhatsappApi(
          ticketsNoQueue.noQueueWhatsappApi || []
        );
      }
    }

    if (
      selectedProfilesIdsMeta?.length > 0 ||
      selectedProfilesIdsWhatsapp?.length > 0 ||
      selectedProfilesIdsWhatsappApi?.length > 0
    ) {
      return;
    }
    init();
  }, []);

  useEffect(() => {
    const ids = onlineUsers
      .filter((user) => user.profile !== "admin")
      .map((user) => user.id);
    if (lastIdTagged.userTicketId === 0) {
      setLastIdTagged({ ticketId: 0, userTicketId: ids[0] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlineUsers]);

  useEffect(() => {
    setPageNumber(1);
  }, [
    searchClicked,
    subTabSearchParam,
    showAll,
    selectedQueueIds,
    selectedUsersIds,
  ]);

  const getSearchedList = async () => {
    try {
      const { data } = await api.get("/filtered-tickets", {
        params: {
          pageNumber,
          searchParam: subTabSearchParam ?? searchParam,
          showAll,
          filters,
        },
      });

      setHasMoreSearch(data.hasMore);
      setTimeout(() => {
        if (pageNumber !== 1) {
          setSearchedList([...searchedList, ...data.tickets]);
        } else {
          setSearchedList(data.tickets);
        }
      }, 500);
    } catch (error) {
      toastError(error);
    } finally {
      if (setLoadingTickets) setLoadingTickets(false);
    }
  };

  useEffect(() => {
    if (loadingTickets) return;
    if (setLoadingTickets) setLoadingTickets(true);
    if (pageNumber && showAll && filters) {
      getSearchedList();
    }
  }, [searchClicked, pageNumber, showAll, tab, setHasMoreSearch]);

  useEffect(() => {
    if (!status && !searchParam && tab !== "search") return;

    if (anchorEl) {
      return;
    }

    if (pageNumber === 1) {
      dispatch({ type: "RESET" });
    }

    const order = {
      order: arrowAnswering || arrowWaiting,
      column: ticketOrder,
    };

    dispatch({
      type: "LOAD_TICKETS",
      payload: { tickets, order },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tickets, status, filters]);

  const userCanSeePotential = useCallback(
    (ticket) => {
      if (ticket.queueId) return true;
      if (ticket.userId === user.id) return true;
      const result =
        selectedProfilesIdsWhatsapp.some(
          (e) => e.whatsappId === ticket.whatsappId && e.userId === user.id
        ) ||
        selectedProfilesIdsMeta.some(
          (e) => e.metaId === ticket.metaId && e.userId === user.id
        ) ||
        selectedProfilesIdsWhatsappApi.some(
          (e) =>
            e.whatsappApiId === ticket.whatsappApiId && e.userId === user.id
        );
      return result;
    },
    [
      selectedProfilesIdsMeta,
      selectedProfilesIdsWhatsapp,
      selectedProfilesIdsWhatsappApi,
      user.id,
    ]
  );

  useEffect(() => {
    if (!socket) return;
    const shouldUpdateTicket = (ticket) => {
      const result =
        (!ticket.userId ||
          ticket.userId === user?.id ||
          selectedUsersIds?.some((id) => id === ticket.userId)) &&
        (!ticket.userId ||
          ticket.userId === user?.id ||
          selectedUsersIds?.some((id) => id === ticket.userId)) &&
        (!ticket.queueId || selectedQueueIds?.indexOf(ticket.queueId) > -1);
      return result;
    };

    const notBelongsToUserQueues = (ticket) =>
      ticket.queueId && selectedQueueIds?.indexOf(ticket.queueId) === -1;

    const notUpsertForEventSource = (data) => {
      return status !== data.ticket?.status;
    };

    const updateUnreadQueue = (data) => {
      dispatch({
        type: "RESET_UNREAD",
        payload: data.ticketId,
      });
    };

    const autoFinishQueue = (data) => {
      dispatch({ type: "DELETE_TICKET", payload: data.ticket.id });
    };

    const updateTicketQueue = (data) => {
      if (status !== data.ticket?.status) return;
      if (
        notBelongsToUserQueues(data.ticket) ||
        (data.ticket.userId &&
          data.ticket.userId !== user?.id &&
          !selectedUsersIds?.some((id) => id === data.ticket.userId))
      ) {
        dispatch({ type: "DELETE_TICKET", payload: data.ticket.id });
      }

      if (shouldUpdateTicket(data.ticket) && !anchorEl) {
        const payload = data.ticket;
        if (payload.inAvaliation) return;

        payload.order = {
          order: arrowAnswering || arrowWaiting,
          column: getSettingValue("ticketOrder"),
        };

        dispatch({
          type: "UPDATE_TICKET",
          payload,
          status,
        });
      }
    };

    const deleteTicketQueue = (data) => {
      if (status === data.status) return;
      dispatch({ type: "DELETE_TICKET", payload: data.ticketId });
    };

    const createMessageQueue = (data) => {
      if (notUpsertForEventSource(data)) return;

      if (
        (userCanSeePotential(data.ticket) ||
          data.ticket.status === "open" ||
          data.ticket.status === "groups") &&
        shouldUpdateTicket(data.ticket) &&
        !anchorEl
      ) {
        const payload = data.ticket;
        payload.order = {
          order: arrowAnswering || arrowWaiting,
          column: ticketOrder,
        };
        payload.order = {
          order: arrowAnswering || arrowWaiting,
          column: ticketOrder,
        };
        dispatch({
          type: "UPDATE_TICKET_UNREAD_MESSAGES",
          payload,
        });
      }
    };

    const updateContactQueue = (data) => {
      data = JSON.parse(data.data);
      dispatch({
        type: "UPDATE_TICKET_CONTACT",
        payload: data.contact,
      });
    };

    registerEvent(
      "tickets",
      (data) => {
        const action = data.action;
        if (action === "updateUnread") {
          updateUnreadQueue(data);
        }
        if (action === "autoFinish") {
          autoFinishQueue(data);
        }
        if (action === "update") {
          updateTicketQueue(data);
        }
        if (action === "delete") {
          deleteTicketQueue(data);
        }
      },
      "ticketsList"
    );

    registerEvent(
      "messages",
      (data) => {
        const action = data.action;
        if (action === "create") {
          createMessageQueue(data);
        }
      },
      "ticketsList"
    );

    registerEvent(
      "contacts",
      (data) => {
        const action = data.action;
        if (action === "update") {
          updateContactQueue(data);
        }
      },
      "ticketsList"
    );

    return () => {
      unregisterEvent("tickets", "ticketsList");
      unregisterEvent("messages", "ticketsList");
      unregisterEvent("contacts", "ticketsList");
    };
  }, [
    status,
    showAll,
    user,
    selectedQueueIds,
    selectedUsersIds,
    arrowAnswering,
    arrowWaiting,
    anchorEl,
    userCanSeePotential,
    socket,
  ]);

  const deleteTicketFromList = useCallback((ticketId) => {
    const deleteTicketFunction = () => {
      if (filters) {
        setSearchedList((prev) =>
          prev.filter((ticket) => ticket.id !== ticketId)
        );
      }
      dispatch({ type: "DELETE_TICKET", payload: ticketId });
    };

    deleteTicketFunction();

    setTimeout(() => {
      deleteTicketFunction();
    }, 4000);
  }, []);

  useEffect(() => {
    setFunctionsDeleteTicketObject((prev) => ({
      ...prev,
      [status]: deleteTicketFromList,
    }));
  }, [status]);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setPageNumber((prevPage) => prevPage + 1);
      }
    });

    if (lastTicket.current) {
      intersectionObserver.observe(lastTicket.current);
    }

    return () => intersectionObserver.disconnect();
  }, [ticketsList, searchedList, setPageNumber]);

  const memoizedTicketList = useMemo(() => {
    if (tab !== "search") {
      return ticketsList.map((ticket, index, arr) => (
        <TicketListItem
          ticket={ticket}
          key={ticket.id}
          column={getSettingValue("ticketOrder")}
          innerRef={
            index === arr.length - 1 && hasMore ? lastTicket : undefined
          }
          setChangeIsFixed={setChangeIsFixed}
          deleteTicketFromList={deleteTicketFromList}
          userCanSeePotential={userCanSeePotential}
          isMobile={isMobile}
        />
      ));
    } else {
      return searchedList?.map((ticket, index, arr) => (
        <TicketListItem
          ticket={ticket}
          key={ticket.id}
          column={getSettingValue("ticketOrder")}
          innerRef={
            index === arr.length - 1 && hasMoreSearch ? lastTicket : undefined
          }
          deleteTicketFromList={deleteTicketFromList}
          userCanSeePotential={userCanSeePotential}
          isMobile={isMobile}
        />
      ));
    }
  }, [
    tab,
    ticketsList,
    searchedList,
    user.profile,
    user.id,
    hasMore,
    hasMoreSearch,
    getSettingValue,
    lastTicket,
    setChangeIsFixed,
    deleteTicketFromList,
    userCanSeePotential,
    isMobile,
  ]);

  return (
    <Paper className={classes.ticketsListWrapper} style={{ ...style }}>
      <Paper square name="closed" elevation={0} className={classes.ticketsList}>
        <List
          style={{
            paddingTop: 0,
            paddingBottom: 100,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {tab !== "search" &&
            memoizedTicketList?.length === 0 &&
            !anchorEl &&
            !loading && (
              <div className={classes.noTicketsDiv}>
                <span className={classes.noTicketsTitle}>
                  {i18n.t("ticketsList.noTicketsTitle")}
                </span>
                <p className={classes.noTicketsText}>
                  {i18n.t("ticketsList.noTicketsMessage")}
                </p>
              </div>
            )}

          {tab !== "search" &&
            memoizedTicketList.length === 0 &&
            anchorEl &&
            !loading && (
              <div className={classes.noTicketsDiv}>
                <span className={classes.noTicketsTitle}>
                  {i18n.t("ticketsList.noTicketsTitle")}
                </span>
                <p className={classes.noTicketsText}>
                  {i18n.t("ticketsList.noTicketsMessage")}
                </p>
              </div>
            )}

          {tab === "search" && searchedList.length === 0 && !loading && (
            <div className={classes.noTicketsDiv}>
              <span className={classes.noTicketsTitle}>
                {i18n.t("ticketsList.noTicketsTitle")}
              </span>
              <p className={classes.noTicketsText}>
                {i18n.t("ticketsList.noTicketsMessage")}
              </p>
            </div>
          )}

          {loading && <TicketsListSkeleton />}

          {memoizedTicketList}

          {(tab === "search" || anchorEl) && (
            <>
              {searchedList?.map((ticket, index, arr) => (
                <TicketListItem
                  ticket={ticket}
                  key={ticket.id}
                  column={getSettingValue("ticketOrder")}
                  innerRef={
                    index === arr.length - 1 && hasMoreSearch
                      ? lastTicket
                      : undefined
                  }
                  deleteTicketFromList={deleteTicketFromList}
                  userCanSeePotential={userCanSeePotential}
                  isMobile={isMobile}
                />
              ))}
            </>
          )}
          {((hasMore && !filters) || (hasMoreSearch && filters)) && (
            <TicketsListSkeleton />
          )}
        </List>
      </Paper>
    </Paper>
  );
};

export default TicketsList;
