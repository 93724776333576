import React from "react";
import { List, Avatar, Typography, Badge } from "@material-ui/core";

const ChatComponent = ({
  selectedChat,
  chats,
  classes,
  handleSelectChat,
  user,
  getInitials,
  isExpanded,
}) => {
  return (
    <>
      {(!selectedChat || isExpanded) && (
        <List className={classes.listContainer}>
          {chats &&
            chats.map((chat) => {
              const chatName =
                user.id === chat.secondUserId
                  ? chat?.firstUser?.name
                  : chat?.secondUser?.name;
              const isOnline =
                user.id === chat.secondUserId
                  ? chat?.firstUser?.isOnline
                  : chat?.secondUser?.isOnline;
              const avatarPhoto =
                user.id === chat.secondUserId
                  ? chat?.firstUser?.photo
                  : chat?.secondUser?.photo;
              const unreadMessages = chat?.unreadMessages?.length;
              return (
                <div
                  className={classes.chatItem}
                  key={chat?.id}
                  onClick={() => handleSelectChat(chat)}
                >
                  {unreadMessages > 0 && (
                    <Badge
                      overlap="rectangular"
                      badgeContent={unreadMessages}
                      color="error"
                      invisible={false}
                      className={classes.badge}
                    />
                  )}
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    variant="dot"
                    className={classes.avatarBadge}
                    color={isOnline ? "primary" : "secondary"}
                  >
                    <Avatar
                      size="larger"
                      className={classes.avatar}
                      src={avatarPhoto}
                    >
                      {getInitials(chatName)}
                    </Avatar>
                  </Badge>
                  <div className={classes.chatContent}>
                    <Typography className={classes.chatPreview}>
                      {chatName}
                    </Typography>
                    <div className={classes.messageContainer}>
                      <Typography className={classes.truncateText}>
                        {chat?.messages[0]?.user?.name}
                        {": "}
                        {chat?.messages[0]?.message}
                      </Typography>
                      {chat?.messages[0]?.createdAt && (
                        <span className={classes.timestampChat}>
                          {new Date(
                            chat?.messages[0]?.createdAt
                          ).toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          })}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </List>
      )}
    </>
  );
};

export default ChatComponent;
