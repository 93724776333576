import React, { useContext, useEffect, useRef, useState } from "react";

// mui
import { Drawer, Box, Grid, IconButton, Badge } from "@material-ui/core";

// context
import { AuthContext } from "../../context/Auth/AuthContext";

// styles
import { internalChatUseStyles } from "./style";

// icons
import ChatIcon from "@material-ui/icons/Chat";
import UserIcon from "@material-ui/icons/Person";
import GroupIcon from "@material-ui/icons/Group";

// components
import MessageComponent from "./messageComponent";
import ChatComponent from "./chatComponent";
import HeaderComponent from "./headerComponent";
import UserComponent from "./userComponent";
import GroupModalComponent from "./groupModalComponent";
import GroupComponent from "./groupComponent ";

// assets
import internalSound from "../../assets/internalChatSong.mp3";

// third party libs
import useSound from "use-sound";
import clsx from "clsx";

//context
import { useInternalChatContext } from "../../context/InternalChat";

const ChatInternal = () => {
  const {
    chats,
    groups,
    users,
    dispatchChats,
    dispatchGroups,
    handleGetInternalChats,
    handleGetInternalGroups,
    handleGetInternalUsers,
    getInitials,
    chatMessages,
    handleCreateMessageHook,
    handleGetMessagesHook,
    soundAlertRef,
    selectedChatRef,
    selectedGroupRef,
    unreadChats,
    unreadGroups,
  } = useInternalChatContext();

  const drawerVariant = "permanent";
  const [drawerWidth, setDrawerWidth] = useState(10);
  const [isExpanded, setIsExpanded] = useState(false);

  const classes = internalChatUseStyles({ drawerWidth, isExpanded });

  const { user } = useContext(AuthContext);

  const [selectedChat, setSelectedChat] = useState(selectedChatRef.current);
  const [selectedGroup, setSelectedGroup] = useState(selectedGroupRef.current);

  const [message, setMessage] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openGroupModal, setOpenGroupModal] = useState(false);

  const [play] = useSound(internalSound);

  const handleSelectChat = async (chat) => {
    try {
      await handleGetMessages({ chatId: chat.id });
      setSelectedGroup(null);
      setSelectedChat(chat);
      selectedChatRef.current = chat;
      const newChats = chats.map((c) => {
        if (c.id === chat.id) {
          return { ...c, unreadMessages: 0 };
        }
        return c;
      });
      dispatchChats({ type: "LOAD_DATA", payload: newChats });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectGroup = async (group) => {
    try {
      await handleGetMessages({ groupId: group.id });
      setSelectedChat(null);
      setSelectedGroup(group);
      selectedGroupRef.current = group;

      const newGroups = groups.map((c) => {
        if (c.id === group.id) {
          return { ...c, unreadMessages: 0 };
        }
        return c;
      });
      dispatchGroups({ type: "LOAD_DATA", payload: newGroups });
    } catch (error) {
      console.error(error);
    }
  };
  const handleSelectUser = async (user) => {
    setSelectedUser(user);
  };

  const handleCreateMessage = async (formData) => {
    const messageUrl = selectedChat
      ? `/internal-chat/${selectedChat.id}/message`
      : `/internal-chat/group/${selectedGroup.id}/message`;
    handleCreateMessageHook({ formData, messageUrl });
    setMessage("");
  };

  const handleGetMessages = async ({ chatId, groupId }) => {
    const messageUrl = chatId
      ? `/internal-chat/${chatId}/messages`
      : `/internal-chat/group/${groupId}/messages`;
    await handleGetMessagesHook({ messageUrl });
  };

  useEffect(() => {
    handleGetInternalChats();
    handleGetInternalUsers();
    handleGetInternalGroups();
  }, []);

  useEffect(() => {
    if (activeTab === 0) {
      handleGetInternalChats();
    }
    if (activeTab === 1) {
      handleGetInternalUsers();
    }
    if (activeTab === 2) {
      handleGetInternalGroups();
    }
  }, [activeTab]);

  useEffect(() => {
    soundAlertRef.current = play;

    if (!("Notification" in window)) {
      console.log("This browser doesn't support notifications");
    } else {
      Notification.requestPermission();
    }
  }, [play]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleOpenGroupModal = () => {
    setOpenGroupModal(true);
  };

  useEffect(() => {
    if (drawerOpen) {
      setDrawerWidth(390);
    } else {
      setDrawerWidth(10);
    }
    setIsExpanded(false);
  }, [drawerOpen]);

  const handleExpandDrawer = () => {
    setDrawerWidth("100vw");
    setIsExpanded(true);
  };

  const handleCollapseDrawer = () => {
    setDrawerWidth(390);
    setIsExpanded(false);
  };

  return (
    <Drawer
      variant={drawerVariant}
      classes={{
        paper: clsx(
          classes.drawerPaper,
          !drawerOpen && classes.drawerPaperClose
        ),
      }}
      open={drawerOpen}
    >
      <Box sx={{ display: "flex", height: "100vh" }}>
        <Box
          sx={{
            width: isExpanded ? "30vw" : "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent:
              (selectedChat || selectedGroup) && !isExpanded
                ? "space-between"
                : "",
          }}
        >
          <HeaderComponent
            classes={classes}
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            selectedChat={selectedChat || selectedGroup}
            selectedChatRef={selectedChat ? selectedChatRef : selectedGroupRef}
            setSelectedChat={selectedGroup ? setSelectedGroup : setSelectedChat}
            activeTab={activeTab}
            handleOpenGroupModal={handleOpenGroupModal}
            handleCollapseDrawer={handleCollapseDrawer}
            handleExpandDrawer={handleExpandDrawer}
            isExpanded={isExpanded}
          />
          <MessageComponent
            selectedChat={!isExpanded ? selectedChat || selectedGroup : null}
            chatMessages={chatMessages}
            message={message}
            setMessage={setMessage}
            handleCreateMessage={handleCreateMessage}
            classes={classes}
            getInitials={getInitials}
            user={user}
            isGroup={!!selectedGroup}
            isExpanded={false}
          />

          {((!selectedChat && !selectedGroup) || isExpanded) && (
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Grid container alignItems="center" style={{ padding: "5px" }}>
                <Grid item xs={4} className={classes.gridItem}>
                  <IconButton
                    className={classes.tabItem}
                    onClick={() => handleTabChange(null, 0)}
                    fullWidth
                    style={{ color: activeTab === 0 ? "black" : "white" }}
                  >
                    {unreadChats.length > 0 && (
                      <Badge
                        overlap="rectangular"
                        badgeContent={unreadChats.length}
                        color="error"
                        invisible={false}
                        className={classes.badge}
                      />
                    )}
                    <ChatIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={4} className={classes.gridItem}>
                  <IconButton
                    className={classes.tabItem}
                    onClick={() => handleTabChange(null, 1)}
                    fullWidth
                    style={{ color: activeTab === 1 ? "black" : "white" }}
                  >
                    <UserIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={4} className={classes.gridItem}>
                  <IconButton
                    className={classes.tabItem}
                    onClick={() => handleTabChange(null, 2)}
                    fullWidth
                    style={{ color: activeTab === 2 ? "black" : "white" }}
                  >
                    {unreadGroups.length > 0 && (
                      <Badge
                        overlap="rectangular"
                        badgeContent={unreadGroups.length}
                        color="error"
                        invisible={false}
                        className={classes.badge}
                      />
                    )}
                    <GroupIcon />
                  </IconButton>
                </Grid>
              </Grid>

              <GroupModalComponent
                open={openGroupModal}
                setOpen={setOpenGroupModal}
                handleGetInternalGroups={handleGetInternalGroups}
                userId={user.id}
              />

              {activeTab === 0 && (
                <>
                  <ChatComponent
                    chats={chats}
                    classes={classes}
                    user={user}
                    handleSelectChat={handleSelectChat}
                    selectedChat={selectedChat}
                    getInitials={getInitials}
                    isExpanded={isExpanded}
                  />
                </>
              )}

              {activeTab === 1 && (
                <>
                  <UserComponent
                    selectedUser={selectedUser}
                    users={users}
                    classes={classes}
                    handleSelectUser={handleSelectUser}
                    getInitials={getInitials}
                    handleSelectChat={handleSelectChat}
                    selectedChat={selectedChat}
                    isExpanded={isExpanded}
                    selectedGroup={selectedGroup}
                  />
                </>
              )}

              {activeTab === 2 && (
                <>
                  <GroupComponent
                    groups={groups}
                    classes={classes}
                    handleSelectGroup={handleSelectGroup}
                    selectedGroup={selectedGroup}
                    getInitials={getInitials}
                    isExpanded={isExpanded}
                  />
                </>
              )}
            </Box>
          )}
        </Box>
        {isExpanded && (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: !(selectedChat || selectedGroup) && "#ecede8",
            }}
          >
            {(selectedChat || selectedGroup) && (
              <MessageComponent
                selectedChat={selectedChat || selectedGroup}
                chatMessages={chatMessages}
                message={message}
                setMessage={setMessage}
                handleCreateMessage={handleCreateMessage}
                classes={classes}
                getInitials={getInitials}
                user={user}
                isGroup={!!selectedGroup}
                isExpanded={isExpanded}
              />
            )}
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default ChatInternal;
