import { useCallback, useEffect, useReducer, useState } from "react";

import { toast } from "react-toastify";

import { useAuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import { dataReducer } from "../../reducers/data";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

function useSettings() {
  const [settings, dispatchSettings] = useReducer(dataReducer, []);
  const {
    setTimeoutUnqueued,
    setIsSocketConnected,
    registerEvent,
    unregisterEvent,
    socket,
  } = useAuthContext();

  const fetchSettings = async () => {
    try {
      const { data } = await api.get("/settings");
      dispatchSettings({ type: "LOAD_DATA", payload: data });
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  useEffect(() => {
    if (!socket) return;
    const updateSettings = async (data) => {
      console.log("update-settings", data);
      dispatchSettings({ type: "UPDATE_DATA", payload: data });
    };

    const updateSocketConnected = () => {
      setIsSocketConnected(false);
    };

    registerEvent(
      "settings",
      (data) => {
        const action = data.action;
        if (action === "update-setting") {
          updateSettings(data.setting);
        }
        if (action === "block-previous-connection") {
          updateSocketConnected(false);
        }
      },
      "useSettings"
    );

    return () => {
      unregisterEvent("settings", "useSettings");
    };
  }, [socket]);

  const getSettingValue = useCallback(
    (key) => {
      const setting = settings.find((setting) => setting.key === key);
      return setting?.value;
    },
    [settings]
  );

  const updateSetting = useCallback(async (key, value) => {
    try {
      await api.put(`/settings/${key}`, {
        value,
      });
      toast.success(i18n.t("settings.success"));
    } catch (err) {
      toastError(err);
    }
  });

  useEffect(() => {
    if (settings.length) {
      const timer = getSettingValue("timeCreateNewTicket");
      setTimeoutUnqueued(Number(timer));
    }
  }, [setTimeoutUnqueued, settings, getSettingValue]);

  return {
    settingLoading: false,
    settings,
    getSettingValue,
    updateSetting,
  };
}

export default useSettings;
