import { parseCookies, setCookie } from "nookies";
import { useCallback, useContext, useEffect, useReducer } from "react";
import toastError from "../../errors/toastError";
import { dataReducer } from "../../reducers/data";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";

const cookieOptions = {
  path: "/",
  domain: ".deskrio.com.br",
  secure: true,
  sameSite: "none",
};

function useNotices() {
  const [notices, dispatchNotices] = useReducer(dataReducer, []);
  const { registerEvent, unregisterEvent, socket } = useContext(AuthContext);

  const getNotices = useCallback(async () => {
    try {
      const { data } = await api.get("/notices");
      dispatchNotices({ type: "LOAD_DATA", payload: data });
    } catch (err) {
      toastError(err);
    }
  }, []);

  const fetchNewNotices = useCallback(async () => {
    if (!socket) return;
    try {
      registerEvent(
        "notices",
        (data) => {
          const action = data.action;
          if (action === "new-notice") {
            dispatchNotices({ type: "LOAD_DATA", payload: [data] });
          }
        },
        "useNotices"
      );
    } catch (err) {
      if (err.message !== "Request failed with status code 400") {
        toastError(err);
      }
    }

    return () => {
      unregisterEvent("notices", "useNotices");
    };
  }, [socket]);

  const markNoticeAsRead = (noticeId) => {
    const { closedNotices } = parseCookies();

    if (closedNotices) {
      const noticeIds = new Set(JSON.parse(closedNotices));
      noticeIds.add(+noticeId);
      return setCookie(
        null,
        "closedNotices",
        JSON.stringify(Array.from(noticeIds)),
        cookieOptions
      );
    }

    return setCookie(
      null,
      "closedNotices",
      JSON.stringify([noticeId]),
      cookieOptions
    );
  };

  useEffect(() => {
    fetchNewNotices();
    getNotices();
  }, [fetchNewNotices, getNotices]);

  return { notices, dispatchNotices, markNoticeAsRead };
}

export default useNotices;
