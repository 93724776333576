import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { getBackendUrlV1 } from "../../config";
import { useAuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { dataReducer } from "../../reducers/data";

const useInternalChat = () => {
  const { isAuth } = useAuthContext();

  const [chats, dispatchChats] = useReducer(dataReducer, []);
  const [groups, dispatchGroups] = useReducer(dataReducer, []);
  const [users, dispatchUsers] = useReducer(dataReducer, []);
  const [chatMessages, dispatchChatMessages] = useReducer(dataReducer, []);
  const [participants, dispatchParticipants] = useReducer(dataReducer, []);

  const soundAlertRef = useRef(null);
  const selectedChatRef = useRef(null);
  const selectedGroupRef = useRef(null);

  const unreadChats = chats?.filter((chat) => chat?.unreadMessages?.length > 0);
  const unreadGroups = groups?.filter(
    (group) => group?.unreadMessages?.length > 0
  );

  const unreadMessages = unreadChats.length + unreadGroups.length;

  const handleGetInternalChats = async () => {
    try {
      const { data } = await api.get("/internal-chat");
      dispatchChats({ type: "RESET_DATA" });
      dispatchChats({ type: "LOAD_DATA", payload: data.internalChats });
    } catch (error) {
      console.error(error);
      toastError("Erro ao buscar chats!");
    }
  };

  const handleGetInternalGroups = async () => {
    try {
      const { data } = await api.get("/internal-chat/groups");
      dispatchGroups({ type: "RESET_DATA" });
      dispatchGroups({ type: "LOAD_DATA", payload: data.internalGroups });
    } catch (error) {
      console.error(error);
      toastError("Erro ao buscar grupos!");
    }
  };

  const handleGetInternalUsers = async () => {
    try {
      const { data } = await api.get(`/internal-chat/users`);
      dispatchUsers({ type: "RESET_DATA" });
      dispatchUsers({ type: "LOAD_DATA", payload: data.internalUsers });
    } catch (error) {
      console.error(error);
      toastError("Erro ao buscar usuários!");
    }
  };

  const handleCreateMessageHook = async ({ formData, messageUrl }) => {
    try {
      await api.post(messageUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      console.error(error);
      toastError("Erro ao enviar mensagem!");
    }
  };

  const handleGetMessagesHook = async ({ messageUrl }) => {
    try {
      const { data } = await api.get(messageUrl);
      dispatchChatMessages({ type: "RESET_DATA" });
      dispatchChatMessages({ type: "LOAD_DATA", payload: data.messages });
    } catch (error) {
      console.error(error);
      toastError("Erro ao buscar mensagens!");
    }
  };

  const handleGroupParticipantsHook = async ({ groupId }) => {
    try {
      const { data } = await api.get(
        `/internal-chat/group/${groupId}/participants`
      );
      dispatchParticipants({ type: "RESET_DATA" });
      dispatchParticipants({
        type: "LOAD_DATA",
        payload: data.internalGroupParticipants,
      });
    } catch (error) {
      console.log(error);
      toastError("Erro ao buscar participantes do grupo!");
    }
  };

  const handleSearchUsersHook = async ({ value }) => {
    try {
      const { data } = await api.get(`/internal-chat/users?search=${value}`);
      dispatchUsers({ type: "RESET_DATA" });
      dispatchUsers({ type: "LOAD_DATA", payload: data.internalUsers });
    } catch (error) {
      console.error(error);
      toastError("Erro ao buscar usuários!");
    }
  };

  const handleSearchChatsHook = async ({ value }) => {
    try {
      const { data } = await api.get(`/internal-chat?search=${value}`);
      dispatchChats({ type: "RESET_DATA" });
      dispatchChats({ type: "LOAD_DATA", payload: data.internalChats });
    } catch (error) {
      console.error(error);
      toastError("Erro ao buscar chats!");
    }
  };

  const handleSearchGroupsHook = async ({ value }) => {
    try {
      const { data } = await api.get(`/internal-chat/groups?search=${value}`);
      dispatchGroups({ type: "RESET_DATA" });
      dispatchGroups({ type: "LOAD_DATA", payload: data.internalGroups });
    } catch (error) {
      console.error(error);
      toastError("Erro ao buscar grupos!");
    }
  };

  const handleCreateGroupHook = async ({ userIds, groupName }) => {
    try {
      await api.post("/internal-chat/group", {
        userIds,
        groupName,
      });
      await handleGetInternalGroups();
    } catch (error) {
      console.error(error);
      toastError("Erro ao criar grupo!");
    }
  };

  const getInitials = (name) => {
    if (!name) {
      return "NA";
    }
    const nameArray = name?.split(" ") || "NA";
    const initials = nameArray?.map((word) => word.charAt(0));
    return initials.join("");
  };

  const handleUpdateMessage = ({ newMessage, type }) => {
    const { internalChatMessage, unreadMessages, newChat } = newMessage;

    const newChatIdString = String(newChat.id);

    const internalChatIdString = String(
      internalChatMessage[
        type === "chat" ? "internalChatId" : "internalChatGroupId"
      ]
    );

    const currentChatRef = type === "chat" ? selectedChatRef : selectedGroupRef;
    const currentChatId = currentChatRef.current
      ? String(currentChatRef.current.id)
      : null;
    const isSameChat = currentChatId === internalChatIdString;

    if (currentChatRef.current && isSameChat) {
      dispatchChatMessages({
        type: "LOAD_DATA",
        payload: [...chatMessages, internalChatMessage],
      });
    }

    if (!isSameChat) {
      soundAlertRef.current();
    }

    const actualChats = type === "chat" ? chats : groups;

    let newChatData = [];

    const exists = actualChats.find(
      (chat) => String(chat.id) === newChatIdString
    );

    if (exists) {
      newChatData = actualChats.map((chat) => {
        if (String(chat.id) === newChatIdString) {
          return {
            ...chat,
            messages: [internalChatMessage],
            unreadMessages: isSameChat ? 0 : unreadMessages,
          };
        }
        return chat;
      });
    } else {
      newChatData = [
        ...actualChats,
        {
          ...newChat,
          messages: [internalChatMessage],
          unreadMessages: isSameChat ? 0 : unreadMessages,
        },
      ];
    }

    if (type === "chat") {
      dispatchChats({ type: "LOAD_DATA", payload: newChatData });
    } else {
      dispatchGroups({ type: "LOAD_DATA", payload: newChatData });
    }
  };

  const handleSSEConnection = useCallback(async () => {
    try {
      const eventSource = new EventSource(
        `${getBackendUrlV1()}/internal-chat/sse`,
        {
          withCredentials: true,
        }
      );

      eventSource.onmessage = (event) => {
        const newMessage = JSON.parse(event.data);
        const { type } = newMessage;
        handleUpdateMessage({ newMessage, type });
      };
    } catch (err) {
      toastError(err);
    }
  }, []);

  useEffect(() => {
    if (isAuth) {
      handleSSEConnection();
    }
  }, [isAuth, handleSSEConnection]);

  return {
    handleGetInternalChats,
    handleGetInternalGroups,
    handleGetInternalUsers,
    chats,
    groups,
    users,
    chatMessages,
    participants,
    dispatchParticipants,
    dispatchChatMessages,
    dispatchChats,
    dispatchGroups,
    dispatchUsers,
    getInitials,
    handleCreateMessageHook,
    handleGetMessagesHook,
    handleGroupParticipantsHook,
    handleSearchUsersHook,
    handleSearchChatsHook,
    handleSearchGroupsHook,
    handleCreateGroupHook,
    soundAlertRef,
    selectedChatRef,
    selectedGroupRef,
    unreadMessages,
    unreadChats,
    unreadGroups,
  };
};

export default useInternalChat;
