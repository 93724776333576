import React, { useState, useEffect } from "react";
import FilePreviewer from "react-file-previewer";
import { makeStyles } from "@material-ui/core";
import { PDFDocument } from "pdf-lib";

export const useStyles = makeStyles((theme) => ({
  filePreviewCss: {
    width: "250px",
    height: "250px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    "& canvas": {
      width: "100% !important",
      height: "100% !important",
      fit: "contain",
    },
  },
}));

const PDFPreview = ({ pdfUrl }) => {
  const classes = useStyles();
  const [isProtected, setIsProtected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkPDFProtection = async () => {
      try {
        const pdfBytes = await fetch(pdfUrl).then(res => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(pdfBytes, { ignoreEncryption: true });

        if (pdfDoc.isEncrypted) {
          setIsProtected(true);
        }
      } catch (error) {
        console.error("Error loading PDF:", error);
      } finally {
        setIsLoading(false);
      }
    };

    checkPDFProtection();
  }, [pdfUrl]);

  if (isLoading) {
    return <div className={classes.filePreviewCss}>Carregando...</div>;
  }

  if (isProtected) {
    return <div className={classes.filePreviewCss}>PDF protegido com senha não pode ser visualizado.</div>;
  }

  return (
    <div className={classes.filePreviewCss}>
      <FilePreviewer
        file={{
          url: pdfUrl,
          name: pdfUrl.split("/files/")[1],
        }}
        hideControls={true}
      />
    </div>
  );
};

export default PDFPreview;