import { useState, useEffect } from "react";
import io from "socket.io-client";
import { getBackendUrl } from "../../config";
import { decryptData } from "../../helpers/cryptSocket";

const useSocket = ({ isAuth }) => {
  const [socket, setSocket] = useState(null);
  const eventListeners = useState({})[0]; // Preserva o estado entre renders

  useEffect(() => {
    if (!isAuth) {
      if (socket) {
        socket.disconnect();
        setSocket(null);
      }
      return;
    }
    if (socket) return;
    const socketConfig = {
      extraHeaders: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
      secure: true,
      withCredentials: true,
    };

    const newSocket = io(getBackendUrl(), socketConfig);
    setSocket(newSocket);

    return () => {
      if (socket) {
        Object.keys(eventListeners).forEach((eventName) => {
          socket.off(eventName);
        });
        socket.disconnect();
        setSocket(null);
      }
    };
  }, [isAuth]);

  const registerEvent = (eventName, handler, uniqueId) => {
    if (!socket) return;

    if (!eventListeners[eventName]) {
      eventListeners[eventName] = [];

      const aggregatedHandler = async (data) => {
        const decryptedData = await decryptData(data);
        eventListeners[eventName].forEach(({ handler }) =>
          handler(JSON.parse(decryptedData))
        );
      };

      socket.on(eventName, aggregatedHandler);
    }

    eventListeners[eventName].push({ uniqueId, handler });
  };

  const unregisterEvent = (eventName, uniqueId) => {
    if (!socket || !eventListeners[eventName]) return;
    eventListeners[eventName] = eventListeners[eventName].filter(
      ({ uniqueId: id }) => id !== uniqueId
    );
    if (eventListeners[eventName].length === 0) {
      delete eventListeners[eventName];
      socket.off(eventName);
    }
  };

  return {
    registerEvent,
    unregisterEvent,
    socket,
  };
};

export default useSocket;
