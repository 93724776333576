import React, { useContext, useEffect, useState } from "react";
import QRCode from "qrcode.react";
import toastError from "../../errors/toastError";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import { toast } from "react-toastify";

const QrcodeModal = ({ open, onClose, whatsAppId, isQrCode }) => {
  const [qrCode, setQrCode] = useState("");
  const { registerEvent, unregisterEvent, socket } = useContext(AuthContext);
  const [number, setNumber] = useState("");
  const [code, setCode] = useState("");
  const [requestedPairCode, setRequestedPairCode] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const [isValidNumber, setIsValidNumber] = useState(true);

  const requestNewQrCode = async () => {
    if (!whatsAppId) return;

    try {
      await api.post(`/restartsession/${whatsAppId}`);
      toast.success("QRCode gerado com sucesso!");
    } catch (err) {
      console.log(err);
      toastError(err);
    }
  };

  const requestPairCode = async () => {
    const cleanedNumber = number.replace(/\D/g, "");
    if (!whatsAppId || !cleanedNumber || !isValidNumber) return;

    if (!isQrCode) {
      await requestNewQrCode();
    }

    try {
      setTimeout(async () => {
        const { data } = await api.post(
          `/whatsappsession-pairing/${whatsAppId}`,
          {
            number: cleanedNumber,
          }
        );
        setRequestedPairCode(true);
        setLoadingCode(true);
        toast.info(data.message);
      }, 3000);
    } catch (err) {
      toastError(err);
    }
  };

  const validatePhoneNumber = (inputNumber) => {
    const cleanedNumber = inputNumber.replace(/\D/g, "");

    if (/^\d{5,15}$/.test(cleanedNumber)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (open) {
      setCode("");
      setNumber("");
      setRequestedPairCode(false);
      setIsValidNumber(true);
      const fetchSession = async () => {
        if (!whatsAppId) return;

        try {
          const { data } = await api.get(`/whatsapp/${whatsAppId}`);
          setQrCode(data.qrcode);
        } catch (err) {
          toastError(err);
        }
      };
      fetchSession();
    }
  }, [whatsAppId, open]);

  useEffect(() => {
    if (!whatsAppId || !socket) return;
    const handleEventSourceWhatsappSessionUpdate = (data) => {
      if (data.session.id === whatsAppId) {
        setQrCode(data.session.qrcode);
      }

      if (data.session.qrcode === "") {
        onClose();
      }
    };

    const handleEventSourceWhatsappSessionPairCode = (data) => {
      if (String(data.message.whatsappId) === String(whatsAppId)) {
        setCode(data.message.code);
        setLoadingCode(false);
        toast.info(data.message.message);
      }
    };

    registerEvent(
      "whatsappSession",
      (data) => {
        const action = data.action;
        if (action === "pairCode") {
          handleEventSourceWhatsappSessionPairCode(data);
        }
        if (action === "update") {
          handleEventSourceWhatsappSessionUpdate(data);
        }
      },
      "qrCodeModal"
    );

    return () => {
      unregisterEvent("whatsappSession", "qrCodeModal");
    };
  }, [whatsAppId, onClose, socket]);

  const handleNumberChange = (e) => {
    const inputNumber = e.target.value;
    setNumber(inputNumber);
    setIsValidNumber(validatePhoneNumber(inputNumber));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" scroll="paper">
      <DialogContent>
        <Grid
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{
            minWidth: "300px",
            minHeight: "300px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isQrCode && (
            <Grid item style={{ marginTop: "10px" }}>
              <Paper elevation={0}>
                <Typography color="primary" variant="h6" gutterBottom>
                  {i18n.t("qrCode.message")}
                </Typography>
                {qrCode ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <QRCode value={qrCode} size={256} />
                  </div>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => requestNewQrCode()}
                  >
                    Gerar novo QRCode
                  </Button>
                )}
              </Paper>
            </Grid>
          )}
          {!requestedPairCode && !isQrCode && (
            <Grid item style={{ marginTop: "10px", width: "100%" }}>
              <TextField
                label="Número de celular"
                fullWidth
                value={number}
                onChange={handleNumberChange}
                error={!isValidNumber}
                helperText={!isValidNumber ? "Número inválido" : ""}
              />
            </Grid>
          )}
          {!requestedPairCode && !isQrCode && (
            <Grid item style={{ marginTop: "10px", width: "100%" }}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => requestPairCode()}
                disabled={
                  requestedPairCode || number.length < 1 || !isValidNumber
                }
              >
                Enviar
              </Button>
            </Grid>
          )}
          {requestedPairCode && !isQrCode && (
            <Grid
              item
              style={{
                marginTop: "10px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {loadingCode && <CircularProgress color="inherit" size={50} />}
              <Typography variant="h3" color="secondary" gutterBottom>
                {code}
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(QrcodeModal);
