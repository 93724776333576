import { getSocketKey } from "../config";

const SECRET_KEY = getSocketKey() || "mysecret_crypto_key_to_32_length";

export const decryptData = async (encryptedData) => {
  const [ivHex, encryptedHex] = encryptedData.split(":");
  const iv = new Uint8Array(
    ivHex.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
  );
  const encrypted = new Uint8Array(
    encryptedHex.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
  );

  const key = await crypto.subtle.importKey(
    "raw",
    new TextEncoder().encode(SECRET_KEY),
    "AES-CTR",
    false,
    ["decrypt"]
  );

  const decrypted = await crypto.subtle.decrypt(
    {
      name: "AES-CTR",
      counter: iv,
      length: 128,
    },
    key,
    encrypted
  );

  return new TextDecoder().decode(decrypted);
};
