import { useContext, useEffect, useState } from "react";

const useImportContacts = ({
  user,
  eventsAuth: { registerEvent, unregisterEvent, socket },
}) => {
  const [progress, setProgress] = useState(null);
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    if (!socket) return;
    const handleEventSourceImportContact = (data) => {
      const { percentage, validNumbersArray, invalidNumbersArray } = data;
      setProgress(percentage);
      if (validNumbersArray && invalidNumbersArray) {
        setProgress(null);
        setShowResults({ validNumbersArray, invalidNumbersArray });
      }
    };

    if (user.id) {
      registerEvent(
        "importContact",
        (data) => {
          const action = data.action;
          if (action === "sendPercentageProgress") {
            handleEventSourceImportContact(data);
          }
        },
        "useImportContacts"
      );
    }

    return () => {
      unregisterEvent("importContact", "useImportContacts");
    };
  }, [user.id, socket]);

  return { progress, showResults, setShowResults };
};

export default useImportContacts;
