import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  previewContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  audioPreview: {
    maxWidth: "100%",
  },
  videoPreview: {
    width: "100%",
    maxWidth: "100%",
  },
  imagePreview: {
    width: "100%",
    height: "auto",
    maxWidth: "100%",
  },
  unsupportedFile: {
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  downloadButton: {
    marginTop: theme.spacing(1),
  },
}));

const InternalChatFilePreview = ({ file }) => {
  const classes = useStyles();
  const { type, url } = file;

  const renderPreview = () => {
    if (type.includes("image")) {
      return (
        <img
          src={url}
          srcSet={url}
          alt="Image Preview"
          className={classes.imagePreview}
        />
      );
    }
    if (type.includes("audio")) {
      return (
        <audio controls className={classes.audioPreview}>
          <source
            src={url}
            type="audio/mp3"
            style={{
              width: "100%",
            }}
          />
        </audio>
      );
    }
    if (type.includes("video")) {
      return (
        <video src={url} controls className={classes.videoPreview}>
          <source src={url} type={type} />
          Your browser does not support the video element.
        </video>
      );
    }
    return (
      <div className={classes.unsupportedFile}>
        Unsupported file type
        <br />
        <Button
          variant="contained"
          color="primary"
          className={classes.downloadButton}
          href={url}
          download
        >
          Download
        </Button>
      </div>
    );
  };

  return <div className={classes.previewContainer}>{renderPreview()}</div>;
};

export default InternalChatFilePreview;
