import { useState, useEffect, useReducer, useCallback } from "react";
import toastError from "../../errors/toastError";

import api from "../../services/api";
import { useAuthContext } from "../../context/Auth/AuthContext";

const reducer = (state, action) => {
  if (action.type === "LOAD_WHATSAPPS") {
    const whatsApps = action.payload;

    return [...whatsApps];
  }

  if (action.type === "UPDATE_WHATSAPPS") {
    const whatsApp = action.payload;
    const whatsAppIndex = state.findIndex(
      (s) => s.id === whatsApp.id && s.isOficial === whatsApp.isOficial
    );

    if (whatsAppIndex !== -1) {
      state[whatsAppIndex] = { ...state[whatsAppIndex], ...whatsApp };
      return [...state];
    } else {
      return [whatsApp, ...state];
    }
  }

  if (action.type === "UPDATE_SESSION") {
    const whatsApp = action.payload;
    const whatsAppIndex = state.findIndex(
      (s) => s.id === whatsApp.id && s.isOficial === whatsApp.isOficial
    );

    if (whatsAppIndex !== -1) {
      state[whatsAppIndex].status = whatsApp.status;
      state[whatsAppIndex].updatedAt = whatsApp.updatedAt;
      state[whatsAppIndex].qrcode = whatsApp.qrcode;
      state[whatsAppIndex].retries = whatsApp.retries;
      return [...state];
    } else {
      return [...state];
    }
  }

  if (action.type === "DELETE_WHATSAPPS") {
    const whatsAppId = action.payload;

    const whatsAppIndex = state.findIndex(
      (s) => s.id === whatsAppId && s.isOficial === true
    );
    if (whatsAppIndex !== -1) {
      state.splice(whatsAppIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useWhatsApps = () => {
  const [whatsApps, dispatch] = useReducer(reducer, []);
  const [whatsAppApis, dispatchApi] = useReducer(reducer, []);
  const [loading, setLoading] = useState(true);
  const user = JSON.parse(localStorage.getItem("user", ""));

  const { registerEvent, unregisterEvent, socket } = useAuthContext();

  const getConnections = useCallback(async () => {
    setLoading(true);
    try {
      const [{ data: whatsapps }, { data: whatsappApis }] = await Promise.all([
        await api.get("/whatsapp/"),
        await api.get("/whatsapp-api/"),
      ]);

      dispatch({ type: "LOAD_WHATSAPPS", payload: whatsapps });
      dispatchApi({
        type: "LOAD_WHATSAPPS",
        payload: [...whatsappApis.map((e) => ({ ...e, isOficial: true }))],
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  }, []);

  useEffect(() => {
    getConnections();
  }, [getConnections]);

  useEffect(() => {
    if (!socket) return;
    if (!user) return;

    const handleEventSourceWhatsappUpdate = (data) => {
      if (data.whatsapp.isOficial) {
        dispatchApi({ type: "UPDATE_WHATSAPPS", payload: data.whatsapp });
      } else {
        dispatch({ type: "UPDATE_WHATSAPPS", payload: data.whatsapp });
      }
    };

    const handleEventSourceWhatsappDelete = (data) => {
      if (data.whatsapp.isOficial) {
        dispatchApi({ type: "DELETE_WHATSAPPS", payload: data.whatsappId });
      } else {
        dispatch({ type: "DELETE_WHATSAPPS", payload: data.whatsappId });
      }
    };

    const handleEventSourceWhatsappSessionsUpdate = (data) => {
      if (data.session.isOficial) {
        dispatchApi({ type: "UPDATE_SESSION", payload: data.session });
      } else {
        dispatch({ type: "UPDATE_SESSION", payload: data.session });
      }
    };

    registerEvent(
      "whatsapps",
      (data) => {
        const action = data.action;
        if (action === "update") {
          handleEventSourceWhatsappUpdate(data);
        }
        if (action === "delete") {
          handleEventSourceWhatsappDelete(data);
        }
      },
      "useWhatsApps"
    );

    registerEvent(
      "whatsappSession",
      (data) => {
        const action = data.action;
        if (action === "update") {
          handleEventSourceWhatsappSessionsUpdate(data);
        }
      },
      "useWhatsApps"
    );

    return () => {
      unregisterEvent("whatsapps", "useWhatsApps");
      unregisterEvent("whatsappSession", "useWhatsApps");
    };
  }, [socket]);

  return { whatsApps, whatsAppApis, loading, getConnections };
};

export default useWhatsApps;
