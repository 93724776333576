import React from "react";
import { List, Avatar, Typography, Badge } from "@material-ui/core";

const GroupComponent = ({
  selectedGroup,
  groups,
  classes,
  handleSelectGroup,
  getInitials,
  isExpanded,
}) => {
  return (
    <>
      {(!selectedGroup || isExpanded) && (
        <List className={classes.listContainer}>
          {groups &&
            groups.map((group) => {
              const groupName = group?.conversationName;
              const unreadMessages = group?.unreadMessages?.length;
              return (
                <div
                  className={classes.chatItem}
                  key={group?.id}
                  onClick={() => handleSelectGroup(group)}
                >
                  {unreadMessages > 0 && (
                    <Badge
                      overlap="rectangular"
                      badgeContent={unreadMessages}
                      color="error"
                      invisible={false}
                      className={classes.badge}
                    />
                  )}
                  <Avatar size="small" className={classes.avatar}>
                    {getInitials(groupName)}
                  </Avatar>
                  <div className={classes.chatContent}>
                    <Typography className={classes.groupPreview}>
                      {groupName}
                    </Typography>
                    <div className={classes.messageContainer}>
                      <Typography className={classes.truncateText}>
                        {group?.messages[0]?.user?.name}
                        {": "}
                        {group?.messages[0]?.message}
                      </Typography>
                      {group?.messages[0]?.createdAt && (
                        <span className={classes.timestampChat}>
                          {new Date(
                            group?.messages[0]?.createdAt
                          ).toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          })}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </List>
      )}
    </>
  );
};

export default GroupComponent;
