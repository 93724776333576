import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";

import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import useSound from "use-sound";
import { makeStyles } from "@material-ui/core/styles";

import { i18n } from "../../translate/i18n";
import alertSound from "../../assets/sound.mp3";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    overflowY: "auto",
    maxHeight: 350,
    ...theme.scrollbarStyles,
  },
  popoverPaper: {
    width: "100%",
    maxWidth: 350,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      maxWidth: 270,
    },
  },
  noShadow: {
    boxShadow: "none !important",
  },
}));

const NotificationsPopOver = () => {
  const classes = useStyles();

  const history = useHistory();
  const {
    user,
    timeoutUnqueued,
    registerEvent,
    unregisterEvent,
    socket,
    ticketsNoQueue,
  } = useContext(AuthContext);
  const ticketIdUrl = +history.location.pathname.split("/")[2];
  const ticketIdRef = useRef(ticketIdUrl);
  const { profile, queues } = user;
  const [messageTimeouts, setMessageTimeouts] = useState([]);

  const [, setDesktopNotifications] = useState([]);

  const [play] = useSound(alertSound);
  const soundAlertRef = useRef();

  const historyRef = useRef(history);

  useEffect(() => {
    soundAlertRef.current = play;

    if (!("Notification" in window)) {
      console.log("This browser doesn't support notifications");
    } else {
      Notification.requestPermission();
    }
  }, [play]);

  const userCanSeePotential = useCallback(
    (ticket) => {
      return (
        ticketsNoQueue.noQueueWhatsapp.some(
          (e) => e.whatsappId === ticket.whatsappId && e.userId === user.id
        ) ||
        ticketsNoQueue.noQueueMeta.some(
          (e) => e.metaId === ticket.metaId && e.userId === user.id
        ) ||
        ticketsNoQueue.noQueueWhatsappApi.some(
          (e) =>
            e.whatsappApiId === ticket.whatsappApiId && e.userId === user.id
        )
      );
    },
    [ticketsNoQueue]
  );

  useEffect(() => {
    ticketIdRef.current = ticketIdUrl;
  }, [ticketIdUrl]);

  useEffect(() => {
    if (!socket) return;
    const handleSourceTicketUpdate = (data) => {
      if (
        data.ticket?.withUnreadMessages &&
        data.ticket.queueId &&
        messageTimeouts.find((m) => m.id === data.ticket.id)
      ) {
        const messageTimeouted = messageTimeouts.find(
          (m) => m.id === data.ticket.id
        );

        const shouldNotNotificate =
          (messageTimeouted.data.message.ticketId === ticketIdRef.current &&
            document.visibilityState === "visible") ||
          (data.ticket.userId && data.ticket.userId !== user?.id) ||
          data.ticket.isGroup ||
          data.ticket.chatbot;

        if (shouldNotNotificate) return;

        clearTimeout(messageTimeouted.messageTimeout);
        setMessageTimeouts((prevState) => {
          return prevState.filter((m) => m.id !== data.ticket.id);
        });

        handleNotifications(messageTimeouted.data);
      }
    };

    const handleSourceTicketUpdateUnread = (data) => {
      if (
        (data.ticket?.withUnreadMessages && data.ticket.queueId) ||
        data.action === "delete"
      ) {
        setDesktopNotifications((prevState) => {
          const notfiticationIndex = prevState.findIndex(
            (n) => n.tag === String(data.ticketId)
          );
          if (notfiticationIndex !== -1) {
            prevState[notfiticationIndex].close();
            prevState.splice(notfiticationIndex, 1);
            return [...prevState];
          }
          return prevState;
        });
      }
    };

    const handleSourceNotifications = (data) => {
      const newDate = new Date();
      const actualDate = newDate.getTime() / 1000; // now date in seconds
      const ticketCreatedAt = Date.parse(data.ticket.createdAt) / 1000; // ticket create in seconds
      const ticketAge = actualDate - ticketCreatedAt; // ticket age in seconds
      const remainingTime = timeoutUnqueued - ticketAge; // remaining time in seconds

      const UserQueues = user.queues.findIndex(
        (users) => users.id === data.ticket.queueId
      );

      if (
        !data.message.read &&
        (data.ticket.userId === user?.id || !data.ticket.userId) &&
        (UserQueues !== -1 || !data.ticket.queueId)
      ) {
        if (profile === "user" && !userCanSeePotential(data.ticket)) {
          return;
        }

        const messageTimeout = setTimeout(() => {
          const shouldNotNotificate =
            (data.message.ticketId === ticketIdRef.current &&
              document.visibilityState === "visible") ||
            (data.ticket.userId && data.ticket.userId !== user?.id) ||
            data.ticket.chatbot;

          if (shouldNotNotificate) return;

          handleNotifications(data);
        }, remainingTime * 1000);

        setMessageTimeouts((prevState) => {
          return [...prevState, { id: data.ticket.id, messageTimeout, data }];
        });
      }
    };

    registerEvent(
      "tickets",
      (data) => {
        const action = data.action;
        if (action === "update") {
          handleSourceTicketUpdate(data);
        }
        if (action === "updateUnread") {
          handleSourceTicketUpdateUnread(data);
        }
      },
      "ticketNotification"
    );

    registerEvent(
      "messages",
      (data) => {
        const action = data.action;
        if (action === "create") {
          handleSourceNotifications(data);
        }
      },
      "ticketNotification"
    );

    return () => {
      unregisterEvent("tickets", "ticketNotification");
      unregisterEvent("messages", "ticketNotification");
    };
  }, [
    user,
    profile,
    queues,
    timeoutUnqueued,
    messageTimeouts,
    userCanSeePotential,
    socket,
  ]);

  const handleNotifications = (data) => {
    const { message, contact, ticket } = data;
    const canSeePotencial = userCanSeePotential(ticket);
    if (!canSeePotencial) return;

    const options = {
      body: `${message.body} - ${format(new Date(), "HH:mm")}`,
      icon: contact.profilePicUrl,
      tag: ticket.id,
      renotify: true,
    };

    if (ticket.status === "open") {
      options.body = `${message.body} - ${format(new Date(), "HH:mm")}`;
    } else if (ticket.status === "pending") {
      options.body = `Há um novo ticket pendente - ${format(
        new Date(),
        "HH:mm"
      )}`;
    }

    const notification = new Notification(
      `${i18n.t("tickets.notification.message")} ${contact.name}`,
      options
    );

    notification.onclick = (e) => {
      e.preventDefault();
      window.focus();
      historyRef.current.push(`/tickets/${ticket.id}`);
    };

    setDesktopNotifications((prevState) => {
      const notfiticationIndex = prevState.findIndex(
        (n) => n.tag === notification.tag
      );
      if (notfiticationIndex !== -1) {
        prevState[notfiticationIndex] = notification;
        return [...prevState];
      }
      return [notification, ...prevState];
    });
    soundAlertRef.current();
  };

  return <></>;
};

export default NotificationsPopOver;
