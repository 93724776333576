import React, { useEffect, useRef, useState } from "react";
import { Avatar, Typography, Menu, MenuItem, Badge } from "@material-ui/core";
import clsx from "clsx";
import InternalChatFilePreview from "./filePreview";
import InputMessageComponent from "./inputMessageComponent";

const MessageComponent = ({
  selectedChat,
  classes,
  user,
  getInitials,
  chatMessages,
  message,
  setMessage,
  handleCreateMessage,
  isGroup,
  isExpanded,
}) => {
  const messageContentRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [previewMessageReply, setPreviewMessageReply] = useState(null);
  const [files, setFiles] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const fileInputRef = useRef(null);

  const chatName =
    user.id === selectedChat?.secondUserId
      ? selectedChat?.firstUser?.name || ""
      : selectedChat?.secondUser?.name || "";
  const groupName = selectedChat?.conversationName;

  const globalName = isGroup ? groupName : chatName;

  const chatIsOnline =
    user.id === selectedChat?.secondUserId
      ? selectedChat?.firstUser?.isOnline
      : selectedChat?.secondUser?.isOnline;

  const chatPhoto =
    user.id === selectedChat?.secondUserId
      ? selectedChat?.firstUser?.photo
      : selectedChat?.secondUser?.photo;

  useEffect(() => {
    if (messageContentRef.current) {
      messageContentRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [chatMessages]);

  useEffect(() => {
    if (messageContentRef.current) {
      messageContentRef.current.scrollIntoView({
        block: "end",
      });
    }
  }, [selectedChat]);

  const handleContextMenu = (event, message) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setSelectedMessage(message);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedMessage(null);
  };

  const handleReplyPreview = () => {
    setPreviewMessageReply(selectedMessage);
    handleCloseMenu();
  };

  const handleMessage = () => {
    const formData = new FormData();
    formData.append("mediaType", "text");
    formData.append("message", message || "");
    formData.append("replyId", previewMessageReply?.id || "");

    if (files.length > 0 && message.trim() !== "") {
      const firstItemFiles = files.shift();
      formData.append("medias", firstItemFiles);
      const newFiles = files.filter((file) => file !== firstItemFiles);
      setFiles(newFiles);
    } else {
      if (files.length > 0) {
        files.forEach((file) => {
          formData.append("medias", file);
        });
      }
      setFiles([]);
    }

    handleCreateMessage(formData);
    setPreviewMessageReply(null);
  };

  const handleFiles = (e) => {
    if (!e.target.files) {
      return;
    }
    const selectedMedias = Array.from(e.target.files);
    setFiles(selectedMedias);
  };

  const handleStartRecording = () => {
    setIsRecording(true);
  };

  const handleStopRecording = () => {
    setIsRecording(false);
  };

  const handleAudioUpload = async (recordedBlob) => {
    try {
      const randomName = Math.random().toString(36).substring(7);
      const file = new File([recordedBlob], `${randomName}.mp3`, {
        type: "audio/mp3",
      });
      const formData = new FormData();
      formData.append("mediaType", "audio");
      formData.append("message", "");
      formData.append("replyId", previewMessageReply?.id || "");
      formData.append("medias", file);
      handleCreateMessage(formData);
    } catch (error) {
      console.error("Error uploading audio:", error);
    }
  };

  return (
    (selectedChat || isExpanded) && (
      <>
        <div className={classes.chatHeader}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            variant="dot"
            className={classes.avatarBadge}
            color={chatIsOnline ? "primary" : "secondary"}
          >
            <Avatar size="larger" className={classes.avatar} src={chatPhoto}>
              {getInitials(chatName)}
            </Avatar>
          </Badge>
          <Typography>{globalName}</Typography>
        </div>
        <div className={classes.messageContent}>
          {chatMessages.map((message) => (
            <div
              key={message.id}
              className={clsx(
                classes.messageContainer,
                message.userId === user.id
                  ? classes.sentMessage
                  : classes.receivedMessage
              )}
              onContextMenu={(event) => handleContextMenu(event, message)}
            >
              {isGroup && (
                <Typography className={classes.messageName}>
                  {message.userId === user.id ? "Você" : message.user.name}
                </Typography>
              )}
              {message.replyToId && (
                <div className={classes.replyMessage}>
                  <legend>{message.replyTo?.user?.name}</legend>
                  <Typography>{message.replyTo?.message}</Typography>
                  {message.replyTo.createdAt && (
                    <span className={classes.timestamp} color="white">
                      {new Date(message.replyTo.createdAt).toLocaleTimeString(
                        "en-US",
                        {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        }
                      )}
                    </span>
                  )}
                </div>
              )}
              {message.mediaUrl && (
                <div className={classes.replyMessage}>
                  <InternalChatFilePreview
                    file={{ type: message.mediaType, url: message.mediaUrl }}
                  />
                </div>
              )}
              <Typography>{message?.message}</Typography>
              {message.createdAt && (
                <span className={classes.timestamp}>
                  {new Date(message.createdAt).toLocaleTimeString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })}
                </span>
              )}
            </div>
          ))}
          <div ref={messageContentRef}></div>
        </div>

        <InputMessageComponent
          classes={classes}
          fileInputRef={fileInputRef}
          handleFiles={handleFiles}
          message={message}
          setMessage={setMessage}
          handleMessage={handleMessage}
          previewMessageReply={previewMessageReply}
          setPreviewMessageReply={setPreviewMessageReply}
          files={files}
          setFiles={setFiles}
          handleStartRecording={handleStartRecording}
          handleStopRecording={handleStopRecording}
          isRecording={isRecording}
          handleAudioUpload={handleAudioUpload}
        />

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleReplyPreview}>Responder</MenuItem>
        </Menu>
      </>
    )
  );
};

export default MessageComponent;
