import React, { useState, useEffect, useContext } from "react";
import {
  Modal,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useInternalChatContext } from "../../context/InternalChat";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  modalContent: {
    backgroundColor: "#fff",
    padding: theme.spacing(2),
    height: "80vh",
    width: "35vw",
    overflowY: "auto",
    borderRadius: "10px",
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  userList: {
    maxHeight: "40vh",
    overflowY: "auto",
  },
  createButton: {
    marginTop: theme.spacing(2),
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
  },
  searchInput: {
    width: "100%",
    color: "black",
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "10px",
  },
}));

const GroupModalComponent = ({ open, setOpen, userId }) => {
  const classes = useStyles();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [timerSearch, setTimerSearch] = useState(null);

  const {
    users,
    dispatchUsers,
    handleGetInternalUsers,
    handleSearchUsersHook,
    handleCreateGroupHook,
  } = useInternalChatContext();

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    if (timerSearch) clearTimeout(timerSearch);

    const newSearchTimer = setTimeout(() => {
      handleSearchUsersHook({ value });
      const newUsers = users.filter((user) => user.id !== userId);
      dispatchUsers({ type: "LOAD_DATA", payload: newUsers });
    }, 500);

    setTimerSearch(newSearchTimer);
  };

  useEffect(() => {
    handleGetInternalUsers();
    const newUsers = users.filter((user) => user.id !== userId);
    dispatchUsers({ type: "LOAD_DATA", payload: newUsers });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const selectUser = (user) => {
    const isSelected = selectedUsers.some(
      (selectedUser) => selectedUser.id === user.id
    );
    if (isSelected) {
      setSelectedUsers(
        selectedUsers.filter((selectedUser) => selectedUser.id !== user.id)
      );
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  const createGroup = async () => {
    const userIds = selectedUsers.map((user) => user.id);
    handleCreateGroupHook({ userIds, groupName });
    handleClose();
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose} className={classes.modal}>
        <div className={classes.modalContent}>
          <h2 className={classes.header}>Criar Grupo</h2>
          <div className={classes.inputContainer}>
            <div
              style={{
                padding: "10px",
              }}
            >
              <TextField
                type="text"
                label="Nome do Grupo"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                className={classes.searchInput}
              />
            </div>
            <div
              style={{
                padding: "10px",
              }}
            >
              <TextField
                type="text"
                placeholder="Search"
                value={searchValue}
                onChange={handleSearchChange}
                className={classes.searchInput}
              />
            </div>
          </div>
          <List className={classes.userList}>
            {users.map((user) => (
              <ListItem key={user.id} onClick={() => selectUser(user)}>
                <Checkbox
                  checked={selectedUsers.some(
                    (selectedUser) => selectedUser.id === user.id
                  )}
                />
                <ListItemText primary={user.name} />
              </ListItem>
            ))}
          </List>
          <Button
            variant="contained"
            color="primary"
            onClick={() => createGroup()}
            className={classes.createButton}
            disabled={selectedUsers.length === 0 || groupName === ""}
          >
            Criar Grupo
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default GroupModalComponent;
