import React, { createContext, useContext } from "react";

import useInternalChat from "../../hooks/useInternalChat";

const InternalChatContext = createContext();

export function useInternalChatContext() {
  return useContext(InternalChatContext);
}

export function InternalChatProvider({ children }) {
  const internalChatHook = useInternalChat();

  return (
    <InternalChatContext.Provider value={{ ...internalChatHook }}>
      {children}
    </InternalChatContext.Provider>
  );
}
