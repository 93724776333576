import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { toast } from "react-toastify";

import RemoveIcon from "@material-ui/icons/Add";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
// import toastError from "../../errors/toastError";

import { makeStyles } from "@material-ui/core/styles";

const filterOptions = createFilterOptions({
  trim: true,
});

const useStyles = makeStyles((theme) => ({
  style: {
    width: 300,
    marginBottom: 20,
  },
}));

const DeletePeoplesModal = ({
  modalOpen,
  onClose,
  ticketWhatsappId,
}) => {
  const classes = useStyles();

  const history = useHistory();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [openTextBox, setOpenTextBox] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [listSelected, setListSelected] = useState([]);
  const numberOfGroup = history.location.pathname.split("/")[2];

  useEffect(() => {
    if (!modalOpen || searchParam.length < 3) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchMembers = async () => {
        setOptions([]);

        const {
          data: { contacts },
        } = await api.get("contacts", {
          params: {
            searchParam,
            ignoreOffset: "true",
            includeGroup: "false",
            channel: "whatsapp",
          },
        });

        const {
          data: {
            contact: { number },
          },
        } = await api.get(`/tickets/${numberOfGroup}`);
        const {
          data: { listContacts },
        } = await api.get(`/group/${number}@g.us`, {
          params: {
            whatsappId: ticketWhatsappId,
          },
        });
        const {
          user,
        } = await api.get("/group/info", {
          params: {
            whatsappId: ticketWhatsappId,
          },
        });

        let arrayOfParticipants = listContacts.map((e) => e.contact.number);
        arrayOfParticipants = arrayOfParticipants.filter(
          (e) => Number(e) !== Number(user)
        );

        const filterContactInGroup = arrayOfParticipants.filter((e) => {
          const verification = contacts.some(
            (i) => Number(i.number) === Number(e)
          );
          return verification;
        });

        const filterNumbersWithoutContact = arrayOfParticipants.filter((e) => {
          const verification = filterContactInGroup.some(
            (i) => Number(i) === Number(e)
          );
          return !verification;
        });

        const filterAll = [
          ...contacts.filter((e) => filterContactInGroup.includes(e.number)),
          ...filterNumbersWithoutContact.map((e) => {
            return { name: e, number: e };
          }),
        ];

        setOptions(filterAll);
        setLoading(false);
      };

      fetchMembers();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, modalOpen]);

  const handleClose = () => {
    setListSelected([]);
    onClose();
    setSearchParam("");
    setOptions([]);
    setOpenTextBox(false);
  };

  const handleExpulse = async () => {
    const {
      data: {
        contact: { number },
      },
    } = await api.get(`/tickets/${numberOfGroup}`);
    const { data } = await api.put("/group/remove", {
      chatID: `${number}@g.us`,
      peoples: listSelected,
      whatsappId: ticketWhatsappId,
    });
    if (data.type === "ERROR") {
      toast.error("Algo deu errado! Verifique o(s) número(s)!");
      setListSelected([]);
      handleClose();
    } else {
      toast.success("Pessoa(s) expulsa(s)!");
      setListSelected([]);
      setOptions([]);
      handleClose();
    }
  };

  const handleListSelected = (e, newValue) => {
    if (newValue?.number) {
      const find = listSelected.find((e) => e.includes(newValue.number));
      if (find) {
        toast.error("Este contato já está na lista de remoção");
        setSearchParam("");
        setOptions([]);
      } else {
        toast.success("Contato adicionado a lista de remoção");
        setSearchParam("");
        setListSelected([...listSelected, `${newValue.number}@c.us`]);
        setOptions([]);
      }
    }
  };

  return (
    <Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">
      <DialogTitle id="form-dialog-title">Remover pessoas</DialogTitle>
      <div
        style={{
          display: openTextBox ? "none" : "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <RemoveIcon
          onClick={() => setOpenTextBox(true)}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          style={{
            display: openTextBox && "none",
            cursor: isHovering && "pointer",
          }}
        />
        <p style={{ display: openTextBox && "none" }}>
          total adicionado: {listSelected.length}
        </p>
      </div>
      {openTextBox && (
        <DialogContent dividers>
          <Autocomplete
            className={classes.style}
            getOptionLabel={(option) => `${option.name} - ${option.number}`}
            onChange={(_e, newValue) => {
              handleListSelected(_e, newValue);
              setOpenTextBox(false);
            }}
            options={options}
            filterOptions={filterOptions}
            freeSolo
            autoHighlight
            noOptionsText={i18n.t("transferTicketModal.noOptions")}
            loading={loading}
            renderInput={(params) => (
              <TextField
                {...params}
                label={i18n.t("transferTicketModal.fieldLabel")}
                variant="outlined"
                required
                autoFocus
                onChange={(e) => setSearchParam(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
          <p>Total selecionado: {listSelected.length}</p>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={handleClose}
          color="secondary"
          disabled={loading}
          variant="outlined"
        >
          {i18n.t("transferTicketModal.buttons.cancel")}
        </Button>
        <ButtonWithSpinner
          variant="contained"
          type="button"
          color="primary"
          onClick={handleExpulse}
          disabled={loading || !listSelected.length > 0}
          loading={loading}
        >
          Expulsar
        </ButtonWithSpinner>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePeoplesModal;
