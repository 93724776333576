import React from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
import mixpanel from "mixpanel-browser";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { getMixpanelToken } from "./config";
import isValidURL from "./helpers/isValidURL";

if (isValidURL()) {
  mixpanel.init(getMixpanelToken(), {
    track_pageview: true,
    persistence: "localStorage",
  });
}

ReactDOM.render(
  <CssBaseline>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </CssBaseline>,
  document.getElementById("root")
);
