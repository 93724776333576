import React, { useState, useEffect, useContext } from "react";
import {
  Modal,
  TextField,
  List,
  ListItem,
  ListItemText,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useInternalChatContext } from "../../context/InternalChat";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  modalContent: {
    backgroundColor: "#fff",
    padding: theme.spacing(2),
    height: "80vh",
    width: "35vw",
    overflowY: "auto",
    borderRadius: "10px",
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  userList: {
    maxHeight: "40vh",
    overflowY: "auto",
  },
  createButton: {
    marginTop: theme.spacing(2),
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
  },
  searchInput: {
    width: "100%",
    color: "black",
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "10px",
  },
}));

const GroupParticipantsModalComponent = ({
  open,
  setOpen,
  groupId,
  groupName,
}) => {
  const classes = useStyles();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { handleGroupParticipantsHook, participants } =
    useInternalChatContext();

  useEffect(() => {
    if (!open || !groupId) return;
    handleGroupParticipantsHook({ groupId });
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const selectUser = (user) => {
    const isSelected = selectedUsers.some(
      (selectedUser) => selectedUser.id === user.id
    );
    if (isSelected) {
      setSelectedUsers(
        selectedUsers.filter((selectedUser) => selectedUser.id !== user.id)
      );
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose} className={classes.modal}>
        <div className={classes.modalContent}>
          <h2 className={classes.header}>Membros</h2>
          <div className={classes.inputContainer}>
            <div
              style={{
                padding: "10px",
              }}
            >
              <TextField
                type="text"
                label="Nome do Grupo"
                value={groupName}
                className={classes.searchInput}
                disabled
              />
            </div>
          </div>
          <List className={classes.userList}>
            {participants.map((participant) => (
              <ListItem
                key={participant.id}
                onClick={() => selectUser(participant.user)}
              >
                <Checkbox
                  checked={selectedUsers.some(
                    (selectedUser) => selectedUser.id === participant.user.id
                  )}
                />
                <ListItemText
                  primary={participant.user.name}
                  secondary={participant.memberType}
                />
              </ListItem>
            ))}
          </List>
        </div>
      </Modal>
    </div>
  );
};

export default GroupParticipantsModalComponent;
