import React from "react";
import {
  IconButton,
  Typography,
  Input,
  Grid,
  ClickAwayListener,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AtachFileIcon from "@material-ui/icons/AttachFile";
import MicIcon from "@material-ui/icons/Mic";
import MoodIcon from "@material-ui/icons/Mood";
import SendIcon from "@material-ui/icons/Send";
import InternalChatFilePreview from "./filePreview";
import { ReactMic } from "react-mic";
import { Picker } from "emoji-mart";

const InputMessageComponent = ({
  previewMessageReply,
  setPreviewMessageReply,
  message,
  setMessage,
  handleMessage,
  handleFiles,
  classes,
  fileInputRef,
  files,
  setFiles,
  handleStartRecording,
  handleStopRecording,
  isRecording,
  handleAudioUpload,
}) => {
  const [showEmoji, setShowEmoji] = React.useState(false);

  const handleAddEmoji = (emoji, values) => {
    const emojiValue = emoji.native;
    setMessage(message + emojiValue);
    setShowEmoji(false);
  };

  return (
    <div className={classes.chatInputContainer}>
      {previewMessageReply && (
        <div className={classes.replyMessage}>
          <IconButton
            className={classes.closeButton}
            onClick={() => setPreviewMessageReply(null)}
          >
            <CloseIcon />
          </IconButton>
          <Typography>{previewMessageReply.message}</Typography>
        </div>
      )}
      {files.length > 0 && (
        <div className={classes.replyMessage} style={{ maxHeight: "300px" }}>
          <IconButton
            className={classes.closeButton}
            onClick={() => setFiles([])}
          >
            <CloseIcon />
          </IconButton>
          <Grid container spacing={2} className={classes.imageGridContainer}>
            {files.map((file, index) => (
              <Grid item xs={4}>
                <div className={classes.imageGridItem}>
                  <InternalChatFilePreview
                    key={index}
                    file={{ url: URL.createObjectURL(file), type: file.type }}
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      <Input
        className={classes.chatInput}
        placeholder="Digite sua mensagem..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        multiline
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey && message.trim() !== "") {
            e.preventDefault();
            handleMessage();
          }
        }}
        endAdornment={
          <>
            {showEmoji ? (
              <div className={classes.emojiBoxMobile}>
                <ClickAwayListener onClickAway={(e) => setShowEmoji(false)}>
                  <Picker
                    perLine={9}
                    showPreview={false}
                    showSkinTones={false}
                    onSelect={handleAddEmoji}
                  />
                </ClickAwayListener>
              </div>
            ) : null}

            <IconButton
              onClick={(e) => setShowEmoji((prevState) => !prevState)}
              className={classes.sendButton}
            >
              <MoodIcon className={classes.sendMessageIcons} />
            </IconButton>
            <IconButton
              onClick={() =>
                isRecording ? handleStopRecording() : handleStartRecording()
              }
              className={classes.sendButton}
              style={{ color: isRecording ? "red" : "inherit" }}
            >
              <MicIcon />
            </IconButton>
            <IconButton
              onClick={() => fileInputRef.current.click()}
              className={classes.sendButton}
            >
              <AtachFileIcon />
            </IconButton>
            <IconButton
              className={classes.sendButton}
              disabled={!message && !files.length > 0}
              onClick={() => {
                if (message.trim() !== "" || files.length > 0) {
                  handleMessage();
                }
              }}
            >
              <SendIcon />
            </IconButton>
            <input
              type="file"
              style={{ display: "none" }}
              ref={fileInputRef}
              multiple
              onChange={(e) => {
                handleFiles(e);
              }}
            />
          </>
        }
      />

      <ReactMic
        record={isRecording}
        onStop={(recordedBlob) => handleAudioUpload(recordedBlob.blob)}
        mimeType="audio/mp3"
        className={classes.audioRecorder}
      />
    </div>
  );
};

export default InputMessageComponent;
