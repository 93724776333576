import React from "react";
import { Typography, List, ListItem, ListItemText } from "@material-ui/core";
import { getBackendUrlV1 } from "../../config";
import { useStylesApi } from "./useStyles";

const UpdateTicketInstruction = () => {
  const classes = useStylesApi();

  return (
    <section className={classes.section}>
      <Typography variant="h5" gutterBottom>
        Atualizando Tickets
      </Typography>

      <section className={classes.section}>
        <Typography variant="h6" gutterBottom>
          Endpoint
        </Typography>
        <Typography>
          - <strong>Método:</strong> PUT
          <br />- <strong>URL:</strong> `{getBackendUrlV1()}
          /api/ticket/update/:ticketId`
        </Typography>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" gutterBottom>
          Parâmetros da Solicitação
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="`connectionId`: ID da conexão (obrigatorio em caso de haver channel)." />
          </ListItem>
          <ListItem>
            <ListItemText primary="`queueId`: ID da fila (opcional)." />
          </ListItem>
          <ListItem>
            <ListItemText primary="`userId`: ID do usuário (opcional)." />
          </ListItem>
          <ListItem>
            <ListItemText primary="`channel`: Canal de comunicação (por exemplo, 'whatsapp', 'facebook', 'instagram', 'whatsappApi'). Opcional em caso de não haver connectionId." />
          </ListItem>
          <ListItem>
            <ListItemText primary="`status`: Estado do ticket (por exemplo, 'open', 'closed', 'pending', 'groups'). Opcional." />
          </ListItem>
        </List>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" gutterBottom>
          Exemplo de Solicitação
        </Typography>
        <Typography paragraph>
          Abaixo está um exemplo de como fazer uma solicitação para atualizar um
          ticket:
        </Typography>
        <code className={classes.code}>
          {`fetch('${getBackendUrlV1()}/api/ticket/update/123', {
  method: 'PUT',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer seu-token'
  },
  body: JSON.stringify({
    connectionId: 123,
    queueId: 456,
    userId: 789,
    channel: 'whatsapp',
    status: 'closed'
  })
})
.then(response => response.json())
.then(data => {
  console.log(data);
})
.catch(error => {
  console.error(error);
});`}
        </code>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" gutterBottom>
          Resposta
        </Typography>
        <Typography paragraph>
          - Se o ticket for atualizado com sucesso, você receberá uma resposta
          com o status 200 e um JSON contendo a mensagem de confirmação.
        </Typography>
        <code className={classes.code}>
          {`{
  "message": "Ticket atualizado com sucesso"
}`}
        </code>
      </section>
    </section>
  );
};

export default UpdateTicketInstruction;
