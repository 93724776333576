import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import api from "../../services/api";
import { useEffect, useState } from "react";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none",
    minWidth: 400,
    maxWidth: 600,
  },
  loading: {
    textAlign: "center",
  },
  logsList: {
    listStyleType: "none",
    padding: 0,
    margin: 0,
  },
  logItem: {
    marginBottom: theme.spacing(2),
  },
  card: {
    marginBottom: theme.spacing(2),
  },
}));

const LogsModal = ({ isOpen, onClose, url }) => {
  const classes = useStyles();
  const [logs, setLogs] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const contentRef = useRef(null);

  const getConnectionLogs = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(url, {
        params: {
          pageNumber,
        },
      });
      if (data.length === 0) {
        setHasMore(false);
        return;
      }
      setLogs((prevLogs) => [...prevLogs, ...data]);
    } catch (error) {
      toastError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = () => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
    if (scrollTop + clientHeight === scrollHeight) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    if (isOpen) {
      getConnectionLogs();
    } else {
      setLogs([]);
      setPageNumber(1);
      setLoading(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (pageNumber > 1) {
      getConnectionLogs();
    }
  }, [pageNumber]);

  return (
    <Dialog
      className={classes.modal}
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      scroll="paper"
    >
      <div className={classes.modalContent}>
        <DialogTitle>Logs</DialogTitle>
        <DialogContent
          ref={contentRef}
          onScroll={handleScroll}
          style={{
            height: "80vh",
          }}
        >
          {loading && <div className={classes.loading}>Carregando...</div>}
          {logs.length > 0 &&
            logs.map((log) => (
              <Card key={log.id} className={classes.card}>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {log.messageType}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    style={{
                      wordBreak: "break-all",
                    }}
                  >
                    {log.message}
                  </Typography>
                  <Typography variant="caption" component="p">
                    {new Date(log.createdAt).toLocaleString()}
                  </Typography>
                </CardContent>
              </Card>
            ))}
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default LogsModal;
