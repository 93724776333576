import { useCallback, useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  customToast: {
    color: "black",
    fontWeight: "bold",
  },
}));

const useAuth = ({
  eventsAuth: { registerEvent, unregisterEvent, socket },
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [isAuth, setIsAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});

  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      const onesignalPushId = localStorage.getItem("onesignal_push_id");

      if (token) {
        config.headers["Authorization"] = `Bearer ${JSON.parse(token)}`;
        setIsAuth(true);
      } else {
        setIsAuth(false);
      }

      if (onesignalPushId) {
        config.headers["onesignal-push-id"] = onesignalPushId;
      }

      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (error?.response?.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;

        const { data } = await api.post("/auth/refresh_token");
        if (data) {
          localStorage.setItem("token", JSON.stringify(data.token));
          localStorage.setItem("user", JSON.stringify(data.user));
          api.defaults.headers.Authorization = `Bearer ${data.token}`;
        }
        return api(originalRequest);
      }
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        api.defaults.headers.Authorization = undefined;
        setIsAuth(false);
      }
      return Promise.reject(error);
    }
  );

  const handleAuthUserCallback = useCallback(async () => {
    try {
      const { data } = await api.post("/auth/refresh_token");
      api.defaults.headers.Authorization = `Bearer ${data.token}`;
      localStorage.setItem("token", JSON.stringify(data.token));
      localStorage.setItem("user", JSON.stringify(data.user));
      setIsAuth(true);
      setUser(data.user);
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const checkTokenExpiration = useCallback(async () => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    if (!token || !user) {
      setLoading(false);
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      history.push("/login");
      return;
    }
    const currentTime = Math.floor(Date.now() / 1000);
    const tokenExpiration = JSON.parse(atob(token.split(".")[1])).exp;
    if (tokenExpiration - currentTime < 300) {
      await handleAuthUserCallback();
      return;
    }
    setLoading(false);
    setIsAuth(true);
    setUser(JSON.parse(localStorage.getItem("user")));
  }, [handleAuthUserCallback]);

  useEffect(() => {
    checkTokenExpiration();
  }, [checkTokenExpiration]);

  useEffect(() => {
    if (!socket) return;
    const handleSourceUserUpdate = (data) => {
      if (data.user.id === user.id) {
        setUser(data.user);
      }
    };

    registerEvent(
      "users",
      (data) => {
        const action = data.action;
        if (action === "update") {
          handleSourceUserUpdate(data);
        }
      },
      "useAuth"
    );

    return () => {
      unregisterEvent("users", "useAuth");
    };
  }, [user, socket]);

  const handleLogin = async (userData) => {
    setLoading(true);

    try {
      const { data } = await api.post("/auth/login", userData);
      localStorage.setItem("token", JSON.stringify(data.token));
      localStorage.setItem("user", JSON.stringify(data.user));
      api.defaults.headers.Authorization = `Bearer ${data.token}`;
      setUser(data.user);
      setIsAuth(true);
      toast.success(i18n.t("auth.toasts.success"));
      history.push("/tickets");
    } catch (err) {
      const errorMessage = `backendErrors.${err.response.data.error}`;
      toast.warn(i18n.t(errorMessage), {
        className: classes.customToast,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    setLoading(true);

    try {
      await api.delete("/auth/logout");
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      api.defaults.headers.Authorization = undefined;
      setTimeout(() => {
        toast.success("Usuário desconectado com sucesso!");
        history.push("/login");
      }, 500);
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
      setIsAuth(false);
      setUser({});
    }
  };

  return { isAuth, user, loading, handleLogin, handleLogout };
};

export default useAuth;
