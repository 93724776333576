import React, { useCallback, useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CSVLink } from "react-csv";
import * as xlsx from "xlsx";

import { CircularProgress, TextField, Typography } from "@material-ui/core";
import { toast } from "react-toastify";

import toastError from "../../errors/toastError";
import api from "../../services/api";
import { useStylesFilter } from "../Filters/filterStyle";

const options = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
};

const ExportTicketsModal = ({ open, onClose, lastSanitizedFilters }) => {
  const classes = useStylesFilter();
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000)
      .toISOString()
      .split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const handleClose = () => {
    setLoading(false);
    setCsvData([]);
    setCsvHeaders([]);
    onClose();
  };

  const checkDuplicates = (acc, curr) => {
    return acc.some(
      (accHeader) => JSON.stringify(accHeader) === JSON.stringify(curr)
    );
  };

  const generateCSVHeaders = useCallback((data) => {
    const defaultLabels = [
      "id",
      "origin",
      "status",
      "created",
      "updated",
      "contactName",
      "contactNumber",
      "user",
      "queueName",
      "connection",
      "option",
      "obs",
      "avaliation",
    ];
    const translated = [
      "Protocolo",
      "Origem",
      "status",
      "Criado em",
      "Fechado em",
      "Nome do contato",
      "Número do contato",
      "Atendente",
      "Setor",
      "Conexão",
      "Motivo",
      "Observação",
      "Avaliação",
    ];

    const headers = [];
    data.forEach((obj) => {
      Object.keys(obj).map((key, i) => {
        const label = `${key}`;
        const headerObj = { label, key };

        if (i < defaultLabels.length) {
          if (key === defaultLabels[i]) {
            headerObj.label = translated[i];
          } else {
            headerObj.label = label;
          }
        }

        const headerExists = checkDuplicates(headers, headerObj);
        if (headerExists === false) {
          headers.push(headerObj);
        }
        return null;
      });
    }, []);

    return headers;
  }, []);

  const defineOrigim = (ticket) => {
    if (!ticket?.action || ticket?.action.length === 0) {
      return "";
    }

    if (
      ticket.action &&
      ticket.action.find(
        (action) => action.fromUser === null || action.fromUser === "Chatbot"
      )
    ) {
      return "Potencial";
    } else {
      return ticket.origin || "Ativo";
    }
  };

  const defineStatus = (ticket) => {
    if (ticket.status === "open") {
      return "Aberto";
    }

    if (ticket.status === "closed") {
      return "Fechado";
    }

    if (ticket.status === "pending") {
      return "Pendente";
    }
  };

  const ticketClosedDate = (ticket) => {
    if (ticket.status === "closed") {
      return `${new Date(ticket.updatedAt).toLocaleDateString(
        "pt-BR",
        options
      )}`;
    }

    return "";
  };

  const generateCSVBody = useCallback((data) => {
    const csvBody = data.map((ticket) => {
      const extraInfosObj = {};

      if (ticket.contact?.extraInfo.length > 0) {
        ticket.contact.extraInfo.forEach(({ name, value, option, field }) => {
          switch (field?.type) {
            case "singleOption":
              if (option) {
                extraInfosObj[name] = option.value;
              }
              break;
            case "multiOption":
              if (option) {
                if (extraInfosObj[name]) {
                  extraInfosObj[
                    name
                  ] = `${extraInfosObj[name]}, ${option.value}`;
                } else {
                  extraInfosObj[name] = [option.value];
                }
              }
              break;
            default:
              extraInfosObj[name] = value;
          }
        });
      }

      if (!ticket.option) {
        return {
          id: ticket.id,
          origin: ticket.origin || defineOrigim(ticket),
          status: defineStatus(ticket),
          created: new Date(ticket.createdAt).toLocaleDateString(
            "pt-BR",
            options
          ),
          updated: ticketClosedDate(ticket),
          contactName: ticket.contact?.name,
          contactNumber: ticket.contact?.number,
          user: ticket.user ? ticket.user?.name : null,
          queueName: ticket.queue?.name,
          connection: ticket.meta?.name || ticket.whatsapp?.name,
          option: ticket.endTicket[0]?.option,
          obs: ticket.endTicket[0]?.body,
          avaliation: ticket?.avaliation?.avaliation,
          ...extraInfosObj,
        };
      }
      return null;
    });

    return csvBody;
  }, []);

  const exportToXLSX = () => {
    const filename = generateFilename("xlsx");
    const workbook = xlsx.utils.book_new();
    const newCsvData = csvData.map((obj) => {
      const arrayOfObj = Object.entries(obj);
      const newObj = {};
      arrayOfObj.forEach(([key, value]) => {
        const headerExists =
          csvHeaders.find((header) => header.key === key) || {};
        newObj[headerExists.label || key] = value;
      });
      return newObj;
    });
    const worksheet = xlsx.utils.json_to_sheet(newCsvData, {
      // header: csvHeaders.map((header) => header.label),
    });
    xlsx.utils.book_append_sheet(workbook, worksheet, "Planilha1");
    xlsx.writeFile(workbook, filename);
    onClose();
  };

  const generateFilename = (name) => {
    const today = new Date();

    const day = today.getDate().toString().padStart(2, "0");
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const date = `${day}-${month}-${today.getFullYear()}`;

    const hours = today.getHours().toString().padStart(2, "0");
    const minutes = today.getMinutes().toString().padStart(2, "0");
    const seconds = today.getSeconds().toString().padStart(2, "0");
    const time = `${hours}-${minutes}-${seconds}`;

    const filename = `tickets_deskrio_${date}_${time}.${name}`;
    return filename;
  };

  const getAllTickets = async () => {
    setLoading(true);

    const createdAtStart = new Date(startDate);
    createdAtStart.setHours(23, 59, 59, 999);

    const createdAtEnd = new Date(endDate);
    createdAtEnd.setDate(createdAtEnd.getDate() + 1);
    createdAtEnd.setHours(23, 59, 59, 999);

    try {
      {
        const lastSanitizedFiltersData = {
          ...lastSanitizedFilters,
          createdAtStart,
          createdAtEnd
        };
        const { data } = await api.post("/end-tickets-filters-all", {
          ...lastSanitizedFiltersData,
        });

        setCsvHeaders(generateCSVHeaders(generateCSVBody(data.tickets)));
        setCsvData(generateCSVBody(data.tickets));
      }
    }
    catch (err) {
      console.log({ err });
      toastError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleStartDateChange = (e) => {
    const selectedStartDate = new Date(e.target.value);
    const selectedEndDate = new Date(endDate);
    const maxEndDate = new Date(selectedStartDate);
    maxEndDate.setMonth(maxEndDate.getMonth() + 1);

    if (selectedStartDate > new Date()) {
      setStartDate(new Date().toISOString().split("T")[0]);
      setEndDate(new Date().toISOString().split("T")[0]);
      toast.info("A data inicial não pode ser maior que a data atual");
    } else {
      if (selectedEndDate > maxEndDate) {
        setEndDate(maxEndDate.toISOString().split("T")[0]);
      } else if (selectedEndDate < selectedStartDate) {
        setEndDate(selectedStartDate.toISOString().split("T")[0]);
      }
      setStartDate(e.target.value);
    }
  };

  const handleEndDateChange = (e) => {
    const selectedEndDate = new Date(e.target.value);
    const selectedStartDate = new Date(startDate);
    const maxEndDate = new Date(selectedStartDate);
    maxEndDate.setMonth(maxEndDate.getMonth() + 1);

    if (selectedEndDate > maxEndDate) {
      setEndDate(maxEndDate.toISOString().split("T")[0]);
      toast.info("Você só pode escolher datas com 30 dias de diferença");
    } else if (selectedEndDate < selectedStartDate) {
      setEndDate(selectedStartDate.toISOString().split("T")[0]);
      toast.info("A data final não pode ser anterior à data inicial");
    } else if (selectedEndDate > new Date()) {
      setEndDate(selectedStartDate.toISOString().split("T")[0]);
      toast.info("A data final não pode ser maior que a data atual");
    } else {
      setEndDate(e.target.value);
    }
  };

  return (
    <Dialog
      style={{ textAlign: "center" }}
      open={open}
      onClose={handleClose}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">
        Tem certeza que quer exportar todos os tickets?
      </DialogTitle>
      <Typography
        style={{
          fontStyle: "italic",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        {loading === true ? (
          "Carregando Tickets..."
        ) : (
          <>{`${csvData.length} tickets disponíveis`}</>
        )}
      </Typography>
      <DialogActions
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {csvData.length === 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "100%",
            }}
          >
            <TextField
              value={startDate || ""}
              label="Data de criação inicial"
              variant="outlined"
              size="small"
              className={classes.textInput}
              type="date"
              InputLabelProps={{ shrink: true }}
              onChange={handleStartDateChange}
              style={{ margin: "5px" }}
            />
            <TextField
              value={endDate || ""}
              label="Data de criação final"
              variant="outlined"
              size="small"
              className={classes.textInput}
              type="date"
              InputLabelProps={{ shrink: true }}
              onChange={handleEndDateChange}
              style={{ margin: "5px" }}
            />
          </div>
        )}
        {loading === true && <CircularProgress size={24} />}
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            variant="outlined"
            onClick={handleClose}
            color="secondary"
            fullWidth
            style={{
              margin: "5px",
            }}
          >
            Cancelar
          </Button>
          {loading === false && csvData.length === 0 && (
            <>
              <Button
                variant="contained"
                onClick={() => getAllTickets()}
                color="primary"
                fullWidth
                style={{
                  margin: "5px",
                }}
                disabled={loading || startDate === "" || endDate === ""}
              >
                Carregar Tickets
              </Button>
            </>
          )}
        </div>
        {csvData.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              fullWidth
              style={{
                margin: "5px",
              }}
              onClick={() => {
                setTimeout(() => {
                  handleClose();
                }, 1000);
              }}
            >
              <CSVLink
                style={{ textDecoration: "none", color: "inherit" }}
                separator=";"
                filename={() => {
                  generateFilename("csv");
                }}
                headers={csvHeaders}
                data={csvData}
              >
                Baixar CSV
              </CSVLink>
            </Button>

            <Button
              onClick={() => {
                exportToXLSX();
              }}
              color="primary"
              variant="contained"
              fullWidth
              style={{
                margin: "5px",
              }}
            >
              Baixar XLSX
            </Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ExportTicketsModal;
