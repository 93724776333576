import React from "react";
import { Typography, List, ListItem, ListItemText } from "@material-ui/core";
import { getBackendUrlV1 } from "../../config";
import { useStylesApi } from "./useStyles";

const SendMessageInstruction = () => {
  const classes = useStylesApi();

  return (
    <section className={classes.section}>
      <Typography variant="h5" gutterBottom>
        Enviando Mensagens
      </Typography>

      <section className={classes.section}>
        <Typography variant="h6" gutterBottom>
          Endpoint
        </Typography>
        <Typography>
          - <strong>Método:</strong> POST
          <br />- <strong>URL:</strong> `{getBackendUrlV1()}
          /api/messages/send`
        </Typography>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" gutterBottom>
          Parâmetros da Solicitação
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="`connectionId`: ID da conexão." />
          </ListItem>
          <ListItem>
            <ListItemText primary="`queueId`: ID da fila (opcional)." />
          </ListItem>
          <ListItem>
            <ListItemText primary="`userId`: ID do usuário (opcional)." />
          </ListItem>
          <ListItem>
            <ListItemText primary="`body`: Corpo da mensagem." />
          </ListItem>
          <ListItem>
            <ListItemText primary="`quotedMsgId`: ID da mensagem citada (opcional)." />
          </ListItem>
          <ListItem>
            <ListItemText primary="`number`: Número de telefone/Id da meta('facebook', 'instagram', 'whatsappApi')." />
          </ListItem>
          <ListItem>
            <ListItemText primary="`name`: Nome do contato da pessoa (Se tiver)" />
          </ListItem>
          <ListItem>
            <ListItemText primary="`channel`: Canal de comunicação (por exemplo, 'whatsapp', 'facebook', 'instagram', 'whatsappApi')." />
          </ListItem>
          <ListItem>
            <ListItemText primary="`medias`: [suas-mídias (Buffer)] (opcional)." />
          </ListItem>
        </List>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" gutterBottom>
          Exemplo de Solicitação
        </Typography>
        <Typography paragraph>
          Abaixo está um exemplo de como fazer uma solicitação para enviar uma
          mensagem:
        </Typography>
        <code className={classes.code}>
          {`fetch('${getBackendUrlV1()}/api/messages/send', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer seu-token'
  },
  body: JSON.stringify({
    connectionId: 123,
    queueId: 456,
    userId: 789,
    body: 'Olá, esta é uma mensagem de teste.',
    quotedMsgId: 987,
    number: '1234567890',
    name: 'Pessoa 01',
    channel: 'whatsapp',
    medias: [suas-mídias (Buffer)]
  })
})
.then(response => response.json())
.then(data => {
  console.log(data);
})
.catch(error => {
  console.error(error);
});`}
        </code>
      </section>

      <section className={classes.section}>
        <Typography variant="h6" gutterBottom>
          Resposta
        </Typography>
        <Typography paragraph>
          - Se a mensagem for enviada com sucesso, você receberá uma resposta
          com o status 200 e um JSON contendo a mensagem de confirmação.
        </Typography>
        <code className={classes.code}>
          {`{
  "message": "Mensagem enviada com sucesso"
}`}
        </code>
      </section>
    </section>
  );
};

export default SendMessageInstruction;
