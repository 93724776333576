import React, { useContext, useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AddIcon from "@material-ui/icons/Add";
import ArrowBack from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";
import ExpandIcon from "@material-ui/icons/ExpandMore";
import CollapseIcon from "@material-ui/icons/ExpandLess";
import GroupIcon from "@material-ui/icons/Group";
import TextField from "@material-ui/core/TextField";
import GroupParticipantsModalComponent from "./groupModalUsers";
import { Badge } from "@material-ui/core";
import { useInternalChatContext } from "../../context/InternalChat";

const HeaderComponent = ({
  classes,
  drawerOpen,
  setDrawerOpen,
  selectedChat,
  selectedChatRef,
  setSelectedChat,
  activeTab,
  handleOpenGroupModal,
  handleCollapseDrawer,
  handleExpandDrawer,
  isExpanded,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [timerSearch, setTimerSearch] = useState(null);
  const [openGroupModal, setOpenGroupModal] = useState(false);

  const {
    handleSearchUsersHook,
    handleSearchChatsHook,
    handleSearchGroupsHook,
    dispatchChatMessages,
    unreadMessages,
  } = useInternalChatContext();

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    if (timerSearch) clearTimeout(timerSearch);

    const newSearchTimer = setTimeout(() => {
      if (activeTab === 0) handleSearchChatsHook({ value });
      else if (activeTab === 1) handleSearchUsersHook({ value });
      else if (activeTab === 2) handleSearchGroupsHook({ value });
    }, 500);

    setTimerSearch(newSearchTimer);
  };

  useEffect(() => {
    setSearchValue("");
  }, [activeTab]);

  return (
    <>
      {unreadMessages > 0 && !drawerOpen && (
        <Badge
          overlap="rectangular"
          badgeContent={unreadMessages}
          color="error"
          invisible={false}
          className={classes.rootBadge}
        />
      )}
      <div className={classes.toolbarIcon}>
        {selectedChat && (
          <IconButton
            className={classes.exitIcon}
            onClick={() => {
              selectedChatRef.current = null;
              setSelectedChat(null);
              dispatchChatMessages({ type: "LOAD_DATA", payload: [] });
            }}
          >
            <ExitToAppIcon style={{ color: "white" }} />
          </IconButton>
        )}
        {selectedChat?.conversationName && (
          <IconButton onClick={() => setOpenGroupModal(true)}>
            <GroupIcon
              fontSize="large"
              style={{
                color: "white",
                backgroundColor: "black",
                borderRadius: "50%",
                padding: "5px",
              }}
            />
          </IconButton>
        )}
        {!isExpanded ? (
          <IconButton onClick={() => handleExpandDrawer()}>
            <ExpandIcon
              fontSize="large"
              style={{
                color: "white",
                backgroundColor: "black",
                borderRadius: "50%",
                padding: "5px",
              }}
            />
          </IconButton>
        ) : (
          <IconButton onClick={() => handleCollapseDrawer()}>
            <CollapseIcon
              fontSize="large"
              style={{
                color: "white",
                backgroundColor: "black",
                borderRadius: "50%",
                padding: "5px",
              }}
            />
          </IconButton>
        )}
        <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
          {!drawerOpen ? (
            <ArrowBack
              fontSize="large"
              style={{
                color: "white",
                position: "fixed",
                backgroundColor: "black",
                borderRadius: "50%",
                padding: "5px",
              }}
            />
          ) : (
            <CloseIcon style={{ color: "white" }} />
          )}
        </IconButton>
      </div>
      {!selectedChat && (
        <div
          style={{
            padding: "10px",
          }}
        >
          <TextField
            type="text"
            placeholder="Search"
            value={searchValue}
            onChange={handleSearchChange}
            className={classes.searchInput}
          />
        </div>
      )}
      {activeTab === 2 && !selectedChat && (
        <Button
          className={classes.createGroupButton}
          endIcon={<AddIcon style={{ color: "white" }} />}
          onClick={() => handleOpenGroupModal()}
        >
          Create Group
        </Button>
      )}

      <GroupParticipantsModalComponent
        groupId={selectedChat?.conversationName && selectedChat.id}
        open={openGroupModal}
        setOpen={setOpenGroupModal}
        groupName={selectedChat?.conversationName}
      />
    </>
  );
};

export default HeaderComponent;
