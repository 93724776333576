import { makeStyles } from "@material-ui/core/styles";

export const useStylesApi = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(3),
    background: theme.palette.background.paper,
  },
  section: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    background: theme.palette.grey[100],
  },
  code: {
    backgroundColor: "black",
    color: "white",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    display: "block",
    whiteSpace: "pre-wrap",
  },
}));
