import React from "react";
import {
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { useStylesApi } from "../../components/APIPageInstructions/useStyles";
import SendMessageInstruction from "../../components/APIPageInstructions/sendMessageInstruction";
import UpdateTicketInstruction from "../../components/APIPageInstructions/updateTicketInstruction";

const DocumentationPage = () => {
  const classes = useStylesApi();

  return (
    <Container maxWidth="lg" className={classes.container}>
      <div className={classes.paper}>
        <Typography variant="h4" gutterBottom>
          Documentação API Externa
        </Typography>

        <section className={classes.section}>
          <Typography variant="h5" gutterBottom>
            Introdução
          </Typography>
          <Typography paragraph>
            Bem-vindo à documentação da API de mensagens do nosso aplicativo.
            Esta documentação irá guiá-lo através do processo de envio de
            mensagens. Você precisará seguir alguns passos simples para se
            comunicar com nossa API de mensagens.
          </Typography>
        </section>

        <section className={classes.section}>
          <Typography variant="h5" gutterBottom>
            Pré-requisitos
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Acesso à nossa API de mensagens." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Conta de usuário com as permissões necessárias." />
            </ListItem>
          </List>
        </section>

        <SendMessageInstruction />
        <UpdateTicketInstruction />
      </div>
    </Container>
  );
};

export default DocumentationPage;
