import React, { useState } from "react";
import {
  List,
  Avatar,
  Typography,
  Menu,
  MenuItem,
  Badge,
} from "@material-ui/core";

import api from "../../services/api";
import { useInternalChatContext } from "../../context/InternalChat";

const UserComponent = ({
  selectedUser,
  classes,
  handleSelectUser,
  handleSelectChat,
  selectedChat,
  selectedGroup,
  isExpanded,
}) => {
  const { users, chats, dispatchChats, getInitials } = useInternalChatContext();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event, user) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    handleSelectUser(user);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFindOrCreateChat = async () => {
    try {
      const { data } = await api.get(
        `/internal-chat/chat/user/${selectedUser.id}`
      );
      if (data.internalChat) {
        handleSelectChat(data.internalChat);

        const newChats = chats.filter(
          (chat) => chat.id !== data.internalChat.id
        );
        dispatchChats({
          type: "LOAD_DATA",
          payload: [data.internalChat, ...newChats],
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      handleClose();
    }
  };

  return (
    <>
      {(!selectedChat || isExpanded || !selectedGroup) && (
        <>
          <List className={classes.listContainer}>
            {users &&
              users.map((user) => (
                <div
                  className={classes.chatItem}
                  key={user?.id}
                  onClick={() => handleSelectUser(user)}
                  onContextMenu={(e) => handleClick(e, user)}
                >
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    variant="dot"
                    className={classes.avatarBadge}
                    color={user?.isOnline ? "primary" : "secondary"}
                  >
                    <Avatar
                      size="larger"
                      className={classes.avatar}
                      src={user.photo}
                    >
                      {getInitials(user?.name)}
                    </Avatar>
                  </Badge>

                  <div className={classes.chatContent}>
                    <Typography className={classes.chatPreview}>
                      {user?.name}
                    </Typography>
                  </div>
                </div>
              ))}
          </List>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem onClick={() => handleFindOrCreateChat()}>
              Conversar
            </MenuItem>
            {/* <MenuItem onClick={() => handleCreateGroup()}>Criar Grupo</MenuItem>
            <MenuItem onClick={handleClose}>Opção 3</MenuItem> */}
          </Menu>
        </>
      )}
    </>
  );
};

export default UserComponent;
