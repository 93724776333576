import { Button } from "@material-ui/core";
import React, { useCallback } from "react";

const getMessageTypeIcon = (type, message) => {
  if (!type || type === null) return message;

  if (String(type).includes("chat")) {
    return message;
  } else if (String(type).includes("video")) {
    return (
      <Button variant="text" startIcon={"🎥"}>
        Vídeo
      </Button>
    );
  } else if (String(type).includes("audio")) {
    return (
      <Button variant="text" startIcon={"🎤"}>
        Áudio
      </Button>
    );
  } else if (String(type).includes("call_log")) {
    return (
      <Button variant="text" startIcon={"📞"}>
        Ligação
      </Button>
    );
  } else if (String(type).includes("template")) {
    return (
      <Button variant="text" startIcon={"🤖"}>
        Template
      </Button>
    );
  } else if (String(type).includes("image")) {
    return (
      <Button variant="text" startIcon={"📸"}>
        Imagem
      </Button>
    );
  } else {
    return (
      <Button variant="text" startIcon={"💾"}>
        Arquivo
      </Button>
    );
  }
};

export default getMessageTypeIcon;
