import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import useImportContacts from "../../hooks/useImportContacts/index.jsx";
import useMixpanel from "../../hooks/useMixpanel/index.jsx";
import useTickets from "../../hooks/useTickets/index.jsx";
import api from "../../services/api.js";
import useSocket from "../../hooks/useSocket/index.jsx";

export const AuthContext = createContext();

export function useAuthContext() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [eventsAuth, setEventsAuth] = useState({});
  const [actualTicketId, setActualTicketId] = useState(null);
  const [isLoadingOpenTicket, setIsLoadingOpenTicket] = useState(false);
  const { loading, user, isAuth, handleLogin, handleLogout, notice } = useAuth({
    eventsAuth,
  });

  const { registerEvent, unregisterEvent, socket } = useSocket({ isAuth });

  const location = useLocation();
  const { track, identify } = useMixpanel(user);

  const { progress, showResults, setShowResults } = useImportContacts({
    user,
    eventsAuth,
  });

  const { useGetTickets } = useTickets();
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [queues, setQueues] = useState([]);
  const [tags, setTags] = useState([]);
  const [connections, setConnections] = useState([]);
  const [connectionsMeta, setConnectionsMeta] = useState([]);
  const [connectionsApi, setConnectionsApi] = useState([]);
  const [listRefresh, setListRefresh] = useState(0);
  const [ticketsNoQueue, setTicketsNoQueue] = useState([]);
  const [relatorio, setRelatorio] = useState("disabled");
  const [timeoutUnqueued, setTimeoutUnqueued] = useState(0);
  const [isSocketConnected, setIsSocketConnected] = useState(true);
  const [selectedUsersIds, setSelectedUsersIds] = useState([]);
  const [previousPath, setPreviousPath] = useState("");

  const isAdminClient = process.env.REACT_APP_ADMIN_CLIENT === "true";

  useEffect(() => {
    if (!isSocketConnected && socket) {
      socket.disconnect();
    }
  }, [isSocketConnected, socket]);

  useEffect(() => {
    if (!isAuth || !socket) return;
    setEventsAuth({
      registerEvent,
      unregisterEvent,
      socket,
    });
  }, [isAuth, socket]);

  const getUsers = useCallback(async () => {
    if (!isAuth) return;
    try {
      const { data } = await api.get("/users");
      setUsers(data);
    } catch (err) {
      console.log(err);
    }
  }, [isAuth]);

  const getQueues = useCallback(async () => {
    if (!isAuth) return;
    try {
      const { data } = await api.get("/queue");
      setQueues(data);
    } catch (err) {
      console.log(err);
    }
  }, [isAuth]);

  const getTags = useCallback(async () => {
    if (!isAuth) return;
    try {
      const { data } = await api.get("/tags");
      setTags(data);
    } catch (err) {
      console.log(err);
    }
  }, [isAuth]);

  const getConnections = useCallback(async () => {
    const [{ data: whatsapps }, { data: whatsappApis }, { data: meta }] =
      await Promise.all([
        await api.get("/whatsapp"),
        await api.get("/whatsapp-api"),
        await api.get("/meta"),
      ]);
    setConnections(whatsapps);
    setConnectionsApi(whatsappApis);
    setConnectionsMeta(meta);
  }, []);

  useEffect(() => {
    if (!isAuth) return;
    getConnections();
  }, [isAuth, getConnections]);

  useEffect(() => {
    setPreviousPath(window.location.href);
  }, [location]);

  useEffect(() => {
    if (user.id && previousPath !== window.location.href && track) {
      track("Page View", {
        "Page Title": window.document.title,
        Referrer: previousPath,
      });
    }
  }, [location, track, user.id, previousPath]);

  useEffect(() => {
    setPreviousPath(window.location.href);
  }, [location]);

  useEffect(() => {
    isAuth && getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, getUsers]);

  useEffect(() => {
    isAuth && getQueues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, getQueues]);

  const getTicketsNoQueue = useCallback(async () => {
    const { data } = await api.get("/tickets-no-queue");
    setTicketsNoQueue(data);
  }, []);

  useEffect(() => {
    isAuth && getTicketsNoQueue();
  }, [isAuth, getTicketsNoQueue]);

  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        notice,
        isAuth,
        handleLogin,
        handleLogout,
        onlineUsers,
        setOnlineUsers,
        users,
        getUsers,
        getQueues,
        queues,
        tags,
        getTags,
        connections,
        connectionsApi,
        getConnections,
        listRefresh,
        setListRefresh,
        relatorio,
        setRelatorio,
        timeoutUnqueued,
        setTimeoutUnqueued,
        isSocketConnected,
        setIsSocketConnected,
        isAdminClient,
        selectedUsersIds,
        setSelectedUsersIds,
        connectionsMeta,
        progress,
        showResults,
        setShowResults,
        track,
        identify,
        ticketsNoQueue,
        useGetTickets,
        setActualTicketId,
        actualTicketId,
        isLoadingOpenTicket,
        setIsLoadingOpenTicket,
        registerEvent,
        unregisterEvent,
        socket,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
