import React, { useRef, useState } from "react";
import api from "../../services/api";
import { toast } from "react-toastify";
import { Photo } from "@material-ui/icons";

const PhotoComponent = ({ selectedPhoto, setSelectedPhoto, photo, userId }) => {
  const inputRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);

  const handlePhotoChange = async (event) => {
    const file = event.target.files[0];
    setSelectedPhoto(file);

    const formUserData = new FormData();

    if (file) {
      formUserData.append("medias", file);

      try {
        await api.put(`/users/photo/${userId}`, formUserData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        toast.success("Foto atualizada com sucesso!");
      } catch (error) {
        toast.error("Erro ao atualizar foto!");
        console.log(error);
      }
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `url('${
          selectedPhoto ? URL.createObjectURL(selectedPhoto) : photo
        }')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
        width: "100%",
        height: "100%",
        minWidth: "350px",
        borderRadius: "10px",
        minHeight: "350px",
        filter: isHovered || !photo ? "brightness(70%)" : "none",
        cursor: "pointer",
        transition: "filter 0.3s ease",
      }}
      onClick={() => inputRef.current.click()}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {(isHovered || !photo) && (
        <Photo
          style={{
            color: "white",
            fontSize: "50px",
            transition: "opacity 0.3s ease",
          }}
        />
      )}

      <input
        ref={inputRef}
        type="file"
        onChange={handlePhotoChange}
        style={{ display: "none" }}
      />
    </div>
  );
};

export default PhotoComponent;
